import React, { useEffect, useState } from 'react';
import KanbanHeader from './KanbanHeader';
import KanbanContainer from './KanbanContainer';
import { useAppContext } from 'providers/AppProvider';
import KanbanProvider from 'providers/KanbanProvider';
import { Card, Col, Form, Nav, Row, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import axios from "axios";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, fa } from '@fortawesome/free-solid-svg-icons';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { socket } from '../../../socket';
import { useNavigate } from 'react-router-dom'
import siteMaps from 'routes/siteMaps';
import paths from '../../../routes/paths';

const CycleView = () => {

  var tempArray = [];
  var tempCount = 0;
  const navigate = useNavigate();

  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();

  const [cycleIsFluid] = useState(isFluid);
  const [cycleIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);
  const [pageNo, setpageNo] = useState([]);
  const [cycleData, setCycleData] = useState([]);
  const [cycleDataIdwise, setcycleDataIdwise] = useState([]);
  const [cycleDates, setCycleDates] = useState([]);
  const [cycleLoaderids, setCycleLoaderids] = useState([]);


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = async (e) => {
    const { deliverydate, headerid, checktype } = e.target.dataset;
    const { checked } = e.target;

    document.getElementById("cycleViewCard" + headerid).classList.add('loader');

    //console.log('handleCheckboxChange', "cycleViewCard" + headerid);

    let tokenStr = localStorage.getItem("accessToken");
    let data = { cycle_id: headerid };

    switch (checktype) {
      case 'refill':
        data.refill = checked ? 1 : 0;
        break;
      case 'billed':
        data.billed = checked ? 1 : 0;
        break;
      case 'tcgrx':
        data.tcgrx_fill = checked ? 1 : 0;
        break;
      case 'manual':
        data.manual_fill = checked ? 1 : 0;
        break;
      case 'pv2':
        data.pv2_status = checked ? 1 : 0;
        break;
      case 'manifest':
        data.manifest = checked ? 1 : 0;
        break;
      default:
        break;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle`, data, {
        headers: { 'Authorization': `Bearer ${tokenStr}` }
      }).then((response) => {
        //console.log('response', response);
        if (response.data.status) {
          //console.log('Cycle data updated successfully');
          setTimeout(() => {
            document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
          }, 1000);
        }
      });


      // setcycleDataIdwise(prevData => ({
      //   ...prevData,
      //   [headerid]: {
      //     ...prevData[headerid],
      //     [checktype]: checked ? 1 : 0
      //   }
      // }));
    } catch (error) {
      console.error('Error updating cycle data:', error);
      document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
    }
  };

  const handleAcknowledgeChange = async (e) => {
    const { deliverydate, headerid, checktype } = e.target.dataset;
    const { checked } = e.target;

    document.getElementById("cycleViewCard" + headerid).classList.add('loader');

    //console.log('handleAcknowledgeChange', "cycleViewCard" + headerid, e.target, checked);

    let tokenStr = localStorage.getItem("accessToken");
    let data = { cycle_id: headerid };
    data.need_acknowledgement = 1;

    if (checked) {
      data.need_acknowledgement = 0;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/cycles/update-need-acknowledgement`, data, {
        headers: { 'Authorization': `Bearer ${tokenStr}` }
      }).then((response) => {
        //console.log('response', response);
        if (response.data.status) {
          //console.log('Cycle data updated successfully');
          setTimeout(() => {
            document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
          }, 1000);
        }
      });
    } catch (error) {
      console.error('Error updating cycle data:', error);
      document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
    }
  };

  useEffect(() => {
    tempArray.push(++tempCount + ' - 1 ' + moment().format('YYYY-MM-DD HH:mm:ss'));
    let tokenStr = localStorage.getItem("accessToken");
    // set pageNo to 1 on initial load
    setpageNo(1);

    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);


    function onConnect() {
      // setIsConnected(true);
    }

    function onDisconnect() {
      console.log('Disconnected from server');
      // setIsConnected(false);
    }

    // function onFooEvent(value) {
    //   console.log('getCycleViewData getCycleViewData ');
    //   socket.emit('clearAllCycles', { tokenStr:tokenStr });
    //   // setFooEvents(previous => [...previous, value]);
    // }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    // socket.on('getCycleViewData', onFooEvent);

    socket.emit('getCycleViewData', { tokenStr: tokenStr, pageNo: 1 });

    socket.on('cycleDataUpdated', async (data) => {
      tempArray.push(++tempCount + ' - 2 ' + moment().format('YYYY-MM-DD HH:mm:ss'));
      // console.log('cycleDataUpdated', data, cycleLoaderids);
      await getCycleViewData(pageNo);
      // console.log('cycleDataUpdated...done', cycleLoaderids);

      // Object.keys(cycleData).map(date => {
      //   cycleData[date].map(item => {
      //     setTimeout(() => {
      //       document.getElementById("cycleViewCard"+item.id).classList.remove('loader');
      //     },500);

      //   });
      // });
    });


    // call getCycleViewData with pageNo
    getCycleViewData(pageNo);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      // socket.off('foo', onFooEvent);

      console.log('Disconnected from server');

      socket.emit('clearAllCycles', { tokenStr: tokenStr });

      setConfig('isFluid', false);
      setConfig('isNavbarVerticalCollapsed', false);
    };
  }, []);

  async function getCycleViewData(pageNo) {
    // axios get call to get data

    let tokenStr = localStorage.getItem("accessToken");
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/cycles/cycle-dashboard/" + pageNo, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

    console.log('getCycleViewData', data.cycleView);
    // setcycleDataIdwise
    var temp = {}
    var dateCount = {}
    Object.keys(data.cycleView).map(date => {
      if (!dateCount.hasOwnProperty(date)) {
        dateCount[date] = {
          total: 0, pv2Count: 0

        }
      }

      // console.log(dateCount);
      data.cycleView[date].map(item => {
        // console.log('item', item, data.cycleView[date][item]);
        // if(!temp.hasOwnProperty(item)) {
        //   temp[item] = data.cycleView[date][item].refill;
        // }
        if (item.id == 4576) {
          // item.census = 1;
          // item.refill = 0;
          // item.billed_status = 0;
          // item.tcgrx_fill = 0;
          // item.manual_fill = 0;
          // item.pv2_status = 0;
          // item.manifest = 0;
          // item.need_acknowledgement = 1;
        }

        dateCount[date].total++;
        if (item.pv2_status != 0) {
          dateCount[date].pv2Count++;
        }
        item.bgColor = 'bg-orange';
        item.fgColorHeader = 'text-white';

        if (item.census == 1) {
          item.bgColor = 'bg-yellow';
          item.fgColorHeader = 'text-dark';
        }
        if (item.refill == 1) {
          item.bgColor = 'bg-darkgreen';
        }
        if (item.billed_status == 1) {
          item.bgColor = 'bg-peach';
        }
        if (item.tcgrx_fill == 1) {//Robot
          item.bgColor = 'bg-blue';
        }
        if (item.manual_fill == 1) {
          item.bgColor = 'bg-purple';
        }
        if (item.pv2_status == 1) {
          item.bgColor = 'bg-green';
        }
        if (item.manifest == 1) {
          item.bgColor = 'bg-brown';
        }

        temp[item.id] = item;

      });


    });

    // console.log(temp);
    setCycleDates(dateCount);
    setcycleDataIdwise(temp);
    setCycleData(data.cycleView);

    //console.log("cycleData " + JSON.stringify(cycleData));
  }

  const processData = () => {
    for (const date in cycleData) {
      if (cycleData.hasOwnProperty(date)) {
        const arrayForDate = cycleData[date];
        // Process each array item
        arrayForDate.forEach(item => {
          //console.log(`Processing item for date ${date}:`, item);
        });
      }
    }
  };

  useEffect(() => {
    //console.log('tempArray', tempArray);
    if (Object.keys(cycleData).length > 0) {
      // processData();
    }
  }, [cycleData]);

  const handleCardBodyClick = (facilityid) => {
    //console.log("cardbody" + facilityid);
    //navigate(paths.cycleDelivery(facilityid), { state: { facilityId: facilityid } });
    navigate(paths.cycleDelivery(facilityid));
  }

  return (
    <>
      <Row className="gx-0 gap-2 kanban-header rounded-2 px-x1 py-2 mt-2 mb-3">
        <Col className="d-flex align-items-center">
          October 14
        </Col>
        <Col xs="auto" as={Flex} alignItems="center"></Col>
      </Row>

      <Row className='m-0'>
        {Object.keys(cycleData).map(date => (
          <Col xs={2} className='dayCols px-1'>
            <Card>
              <Card.Header className='dateCol text-white card-header text-center p-2'>
                {moment(date, 'YYYY-MM-DD').format('MM/DD/YY')} [{cycleDates[date].total}]
                <br />
                PV2 [{cycleDates[date].pv2Count}]
              </Card.Header>
              <Card.Body className='px-1 cycleViewCardBody' >
                {cycleData[date].map((item, index) => (
                  <>
                    {item.need_acknowledgement == 0 && <Card className='cycleViewCard my-2' id={"cycleViewCard" + item.id} name={"cycleViewCard" + item.id} role="button" >
                      <Card.Header className={item.bgColor + ' px-3 py-1 ' + item.fgColorHeader}>
                        {item.facility_name}
                      </Card.Header>
                      <Card.Body className={item.bgColor + '-body text-dark px-3 py-1'}  >

                        <Row>
                          <Col xs="6 " onClick={() => handleCardBodyClick(item.facility_id)}>
                            {item.need_refill > 0 && <OverlayTrigger
                              overlay={
                                <Tooltip style={{ position: 'fixed' }} >
                                  {item.need_refill_drugs.map((drug, index) => (
                                    <div key={index}>{drug} </div>
                                  ))}
                                </Tooltip>
                              }
                            >
                              <u class='refill-tooltip fw-bold'> Need Refill: {item.need_refill}</u>
                            </OverlayTrigger>}

                            {item.need_refill == 0 &&
                              <span> Need Refill: {item.need_refill}</span>
                            }

                          </Col>
                          <Col xs="6" className='text-end'>
                            On Hold: {item.on_hold}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            Robot: DOSIS
                          </Col>
                          <Col xs="6" className='text-end'>
                            {
                              item.total_changed_prescriptions > 0 &&
                              <span>Cycle:&nbsp;
                                {item.updated_changed_prescriptions}
                                &nbsp;out of&nbsp;
                                {item.total_changed_prescriptions}
                              </span>
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            Delivery Time: {item.delivery_time}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            Cycle: {moment(item.cycle_start_date, 'YYYY-MM-DD').format('MM/DD/YY')} to {moment(item.cycle_end_date, 'YYYY-MM-DD').format('MM/DD/YY')}
                          </Col>
                        </Row>
                        <Row className="cycle-chks">
                          <Col xs="auto" className='col1'>
                            <div class="form-check-inline me-0">
                              {item.census == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)}
                              {item.census == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)}
                              &nbsp;Census
                            </div>
                          </Col>

                          <Col xs="auto" className='col2'>

                            <div class="form-check-inline me-0">
                              {/* {item.liaison == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)} Liaison
                              {item.liaison == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)} Liaison */}

                              {/* <FontAwesomeIcon className="text-disable" icon="times-circle" /> Liaison */}
                              <FontAwesomeIcon className="text-success" icon="check-circle" /> Liaison
                            </div>

                          </Col>
                        </Row>
                        <Row className="cycle-chks">
                          <Col xs="auto" className='col1'>
                            <Form.Group class="form-check-inline">
                              {/* <Form.Check
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="refill"
                                    name={"is_refill"+ item.id}
                                    id={"is_refill"+ item.id}
                                    checked={cycleDataIdwise[item.id].refill == 1? true : false}
                                    label='Refill'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                              <input
                                type='checkbox'
                                className='form-check-input'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="refill"
                                name={"is_refill" + item.id}
                                id={"is_refill" + item.id}
                                checked={item.refill}
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                              <label title="" for={"is_refill" + item.id} class="form-check-label">&nbsp;Refill</label>
                            </Form.Group>
                          </Col>
                          <Col xs="auto" className='col2'>
                            <Form.Group class="form-check-inline">

                              <input
                                type='checkbox'
                                className='form-check-input'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="billed"
                                name={"chkBilled" + item.id}
                                id={"chkBilled" + item.id}
                                checked={item.billed_status}
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                              <label title="" for={"chkBilled" + item.id} class="form-check-label">&nbsp;Billed</label>

                              {/* <Form.Check                                
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="billed"
                                    name={"chkBilled"+ item.id}
                                    id={"chkBilled"+ item.id}
                                    value={item.billed_status}
                                    checked={cycleDataIdwise[item.id].billed_status == 1? true : false}
                                    label='Billed'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                            </Form.Group>
                          </Col>

                          <Col  xs="auto" >

                            <Form.Group class="form-check-inline">
                              <input
                                type='checkbox'
                                className='form-check-input'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="tcgrx"
                                name={"chkTcgrx" + item.id}
                                id={"chkTcgrx" + item.id}
                                checked={item.tcgrx_fill}
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                              <label title="" for={"chkTcgrx" + item.id} class="form-check-label">&nbsp;Robot</label>



                              {/* <Form.Check                                
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="tcgrx"
                                    name={"chkTCGRx+"+ item.id}
                                    id={"chkTCGRx+"+ item.id}
                                    value={item.tcgrx_fill}
                                    checked={cycleDataIdwise[item.id].tcgrx_fill == 1? true : false}
                                    label=' Robot'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                            </Form.Group>

                          </Col>

                          <Col xs="auto" className='col1'>



                            <Form.Group class="form-check-inline">
                              <Form.Check
                                type='checkbox'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="manual"
                                name={"chkManual+" + item.id}
                                id={"chkManual+" + item.id}
                                value={item.manual_fill}
                                checked={item.manual_fill}
                                label=' Manual'
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                            </Form.Group>


                          </Col>

                          <Col xs="auto" className='col2' >


                            <Form.Group class="form-check-inline">
                              <Form.Check
                                type='checkbox'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="pv2"
                                name={"chkPV2+" + item.id}
                                id={"chkPV2+" + item.id}
                                value={item.pv2_status}
                                checked={item.pv2_status}
                                label="PV2"
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                            </Form.Group>

                          </Col>

                          <Col  xs="auto"  >

                            <Form.Group class="form-check-inline">
                              <Form.Check
                                type='checkbox'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="manifest"
                                name={"chkManifest+" + item.id}
                                id={"chkManifest+" + item.id}
                                value={item.manifest}
                                checked={item.manifest}
                                label=' Manifest'
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                            </Form.Group>


                          </Col>

                        </Row>

                      </Card.Body>
                    </Card>}

                    <div key={index}>
                      {/* {JSON.stringify(item)} Render your item properties here */}
                    </div>
                  </>
                ))}
              </Card.Body>
            </Card>
          </Col>
        ))}
        <Col xs={2} className='px-1'>
          <Card className='h-100'>
            <Card.Header className='dateCol text-white card-header text-center p-2'>
              Updates
              <br />
              &nbsp;
            </Card.Header>
            <Card.Body className='p-1'>

              {Object.keys(cycleData).map(date => (
                <>
                  {cycleData[date].map((item, index) => (
                    <>
                      {item.need_acknowledgement == 1 && <Card className='cycleViewCard my-2' id={"cycleViewCard" + item.id}>
                        <Card.Header className={item.bgColor + ' px-3 py-1 ' + item.fgColorHeader}>
                          {item.facility_name}
                        </Card.Header>
                        <Card.Body className={item.bgColor + '-body text-dark px-3 py-1'} >

                          <Row>
                            <Col xs="6 ">
                              {item.need_refill > 0 && <OverlayTrigger
                                overlay={
                                  <Tooltip style={{ position: 'fixed' }} >
                                    {item.need_refill_drugs.map((drug, index) => (
                                      <div key={index}>{drug} </div>
                                    ))}
                                  </Tooltip>
                                }
                              >
                                <u class='refill-tooltip fw-bold'> Need Refill: {item.need_refill}</u>
                              </OverlayTrigger>}

                              {item.need_refill == 0 &&
                                <span> Need Refill: {item.need_refill}</span>
                              }

                            </Col>
                            <Col xs="6" className='text-end'>
                              On Hold: {item.on_hold}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6">
                              Robot: DOSIS
                            </Col>
                            <Col xs="6" className='text-end'>
                              {
                                item.total_changed_prescriptions > 0 &&
                                <span>Cycle:&nbsp;
                                  {item.updated_changed_prescriptions}
                                  &nbsp;out of&nbsp;
                                  {item.total_changed_prescriptions}
                                </span>
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              Delivery Time: {item.delivery_time}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              Cycle: {moment(item.cycle_start_date, 'YYYY-MM-DD').format('MM/DD/YY')} to {moment(item.cycle_end_date, 'YYYY-MM-DD').format('MM/DD/YY')}
                            </Col>
                          </Row>
                          <Row className="cycle-chks">
                            <Col xs="auto" className='col1'>
                              <div class="form-check-inline me-0">
                                {item.census == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)}
                                {item.census == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)}
                                &nbsp;Census
                              </div>
                            </Col>

                            <Col xs="auto" className='col2'>

                              <div class="form-check-inline me-0">
                                {/* {item.liaison == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)} Liaison
                              {item.liaison == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)} Liaison */}

                                {/* <FontAwesomeIcon className="text-disable" icon="times-circle" /> Liaison */}
                                <FontAwesomeIcon className="text-success" icon="check-circle" /> Liaison
                              </div>

                            </Col>
                          </Row>
                          <Row className="cycle-chks">
                            <Col xs="auto" className=''>
                              <Form.Group class="form-check-inline">
                                {/* <Form.Check
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="refill"
                                    name={"is_refill"+ item.id}
                                    id={"is_refill"+ item.id}
                                    checked={cycleDataIdwise[item.id].refill == 1? true : false}
                                    label='Refill'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  data-deliverydate={date}
                                  data-headerid={item.id}
                                  data-checktype="acknowledged"
                                  name={"is_acknowledged" + item.id}
                                  id={"is_acknowledged" + item.id}
                                  onChange={(e) => {
                                    handleAcknowledgeChange(e);
                                  }}
                                />
                                <label title="" for={"is_acknowledged" + item.id} class="form-check-label">&nbsp;Acknowledged</label>
                              </Form.Group>
                            </Col>









                          </Row>

                        </Card.Body>
                      </Card>}

                    </>
                  ))}
                </>
              ))}




            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CycleView;