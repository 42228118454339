import React from 'react';
import { Card, Col, Form, Row, Button, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import paths, { rootPaths } from '../../../routes/paths';
import HcpModal from './HcpModal';

const AddEditHcpForm = () => {

    const [options, setOptions] = useState([]);
    const [genderoptions, setGenderOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [specialityOptions, setSpecialityOptions] = React.useState([{ key: "Select Speciality", value: "" }]);
    const location = useLocation();
    const locationData = location.state;

    const initialFormData = {
        firstname: '',  //mand
        lastname: '',   //mand
        facilityname: '',   //mand
        speciality: '',
        address: '',
        gender: '',
        city: '',
        state: '',
        zip: '',
        phone: '',  //mand
        email: '',
        fax: '',
        isactive: false
    };

    const [formData, setFormData] = useState(initialFormData);

    const [date, setDate] = useState(null);

    const navigate = useNavigate();
    const formRef = useRef(null);

    const handleHcpModal = (event) => {
        setShowModal(true);
    };

    useEffect(() => {
        async function fetchProviderData() {

            let tokenStr = localStorage.getItem("accessToken");
            let results = JSON.parse(localStorage.getItem("facilitiesData"));
            //console.log("Facility Points " + results);

            // Update the options state
            setOptions([
                { key: 'Select a program', value: '' },
                ...results
            ])

            setGenderOptions([
                {
                    key: 'Select a gender', value: ''
                },
                {
                    key: 'Male', value: 'M'
                },
                {
                    key: 'Female', value: 'F'
                },
                {
                    key: 'Others', value: 'O',
                }
            ])

            let specialityResults = [];

            await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/doctors/list-doctor-speciality", { headers: { 'Authorization': `Bearer ${tokenStr}` } })
                .then((res) => {

                    let objDocSpecialityData = res.data.data;

                    //console.log("HSIB " + JSON.stringify(res.data));

                    objDocSpecialityData.forEach((value) => {

                        specialityResults.push({
                            key: value.id,
                            value: value.speciality,
                        });
                    });

                })

            //console.log("formstatus " + data.formstatus);

            if (locationData != null) {
                setFormData({
                    firstname: locationData.firstname,  //mand
                    lastname: locationData.lastname,   //mand
                    facilityname: '',   //mand
                    address: locationData.address,
                    gender: locationData.gender,
                    city: locationData.city,
                    state: locationData.state,
                    zip: locationData.zip,
                    phone: locationData.phone,  //mand
                    email: locationData.email,
                    fax: locationData.fax,
                    isactive: false
                });

                if (locationData.speciality != "" && locationData.speciality != null) {

                    if (locationData.speciality.includes(",")) {
                        let specialityArray = locationData.speciality.split(',')
                        //console.log("specialityArray " + specialityArray);
                        for (var i = 0; i < specialityArray.length; i++) {
                            setSpecialityOptions([...specialityOptions, { key: specialityArray[i], value: specialityArray[i] }])
                        }
                    }
                    else {
                        setSpecialityOptions([...specialityOptions, { key: locationData.speciality, value: locationData.speciality }])
                    }

                }
            }
        }

        // Trigger the fetch
        fetchProviderData();
    }, []);

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();

        if (e.target.name == "specialitymainform") {

            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsHcpUrl = locationData != null ? `${process.env.REACT_APP_SERVER_URL}` + "/doctors/edit-doctor" : `${process.env.REACT_APP_SERVER_URL}` + "/doctors/add-doctor";

            let specialityManual = [];

            specialityOptions.map((specialityoption) => {

                if (specialityoption.value != "") {
                    specialityManual.push(specialityoption.value);
                }
            });

            let pelmedsHcpData = locationData != null ?
                {
                    //"group_id": 1,
                    "id": locationData.doc_id,
                    "firstname": formData.firstname,
                    "lastname": formData.lastname,
                    "address": formData.address,
                    "speciality": formData.speciality,
                    //"facility": formData.facilityname,
                    //"address2": "address2",
                    "gender": formData.gender,
                    "city": formData.city,
                    "state": formData.state,
                    "zip": formData.zip,
                    "gender": formData.gender,
                    "phone": formData.phone,
                    "fax": formData.fax,
                    speciality_manual: specialityManual
                    //"isactive": formData.isactive
                    //"dea": "dea",
                    //"npi_number": "npi_number",
                    //"doctors_speciality_id": 10
                } :
                {
                    //"group_id": 1,
                    "firstname": formData.firstname,
                    "lastname": formData.lastname,
                    "address": formData.address,
                    "speciality": formData.speciality,
                    //"facility": formData.facilityname,
                    //"address2": "address2",
                    "gender": formData.gender,
                    "city": formData.city,
                    "state": formData.state,
                    "zip": formData.zip,
                    "gender": formData.gender,
                    "phone": formData.phone,
                    "fax": formData.fax,
                    speciality_manual: specialityManual
                    //"isactive": formData.isactive
                    //"dea": "dea",
                    //"npi_number": "npi_number",
                    //"doctors_speciality_id": 10
                }


            //console.log("PelMedsHcpData " + JSON.stringify(pelmedsHcpData));

            const { data } = await axios.post(pelmedsHcpUrl, pelmedsHcpData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            if (data.status) {
                toast.success("Doctors added/updated successfully");
                navigate(paths.viewHcp);
            }
            else {
                toast.info("Error while adding doctor speciaility");
            }

            if (locationData == null) {
                setFormData(initialFormData);
                formRef.current.reset();
            }
            //console.log("DataOutput " + JSON.stringify(data));
        }

    };

    const handleHcpModalValue = (modalvalue) => {
        //console.log("i m in handlepopup" + modalvalue);

        let isDuplicate = handleDrpDuplicates(specialityOptions, modalvalue);
        if (modalvalue != "" && !isDuplicate)
            setSpecialityOptions([...specialityOptions, { key: modalvalue, value: modalvalue }]);
    };

    function handleDrpDuplicates(drpdwnOptions, modalvalue) {

        let isDuplicate = false;

        drpdwnOptions.map((singleoption) => {
            //console.log(singleoption.key + " && " + modalvalue);
            if (singleoption.key.toLowerCase() == modalvalue.toLowerCase()) {
                isDuplicate = true;
            }
        })

        return isDuplicate;
    }

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    /*const handleSpecialityFieldChange = (e) => {
        console.log("Target " + Array.from(e.target.selectedOptions, option => option.value));
        setSpecialityValue(Array.from(e.target.selectedOptions, option => option.value));
    };*/

    return (
        <Form ref={formRef} onSubmit={handleSubmit} name="specialitymainform">
            <Card className="mb-3">
                <Card.Header as="h6" className="bg-body-tertiary">
                    Add HCP
                </Card.Header>
                <Card.Body>
                    <Row className="mb-1">
                        <Col>
                            <Form.Group>
                                <Form.Label className="required">First Name</Form.Label>
                                <Form.Control
                                    placeholder='First Name'
                                    value={formData.firstname}
                                    name="firstname"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className="required">Last Name</Form.Label>
                                <Form.Control
                                    placeholder='Last Name'
                                    value={formData.lastname}
                                    name="lastname"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label className="required">Speciality</Form.Label>
                                <InputGroup>
                                    <Form.Select
                                        name="speciality"
                                        onChange={handleFieldChange}
                                        size="sm"
                                    >
                                        {specialityOptions.map((specialityoption) => {
                                            return (
                                                <option key={specialityoption.value} value={specialityoption.value}>
                                                    {specialityoption.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Button variant="outline-secondary" size="sm" id="btnaddhcp" onClick={handleHcpModal}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Facility</Form.Label>
                                <Form.Select
                                    name="facilityname"
                                    onChange={handleFieldChange}
                                    size="sm">
                                    {options.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                    name="gender"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {genderoptions.map((option) => {
                                        var isSelected = false;
                                        if (locationData != null) {
                                            if (option.value == locationData.gender) {
                                                isSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isSelected}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group className="required">
                                <Form.Label className="required">Office Phone</Form.Label>
                                <MaskedInput
                                    mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control form-control-sm"
                                    guide={false}
                                    placeholder="+1 (XXX) XXX-XXXX"
                                    onChange={handleFieldChange}
                                    size="sm"
                                    name="phone"
                                    value={formData.phone}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    placeholder='City'
                                    value={formData.city}
                                    name="city"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    placeholder='State'
                                    value={formData.state}
                                    name="state"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Zip</Form.Label>
                                <Form.Control
                                    placeholder='Zip'
                                    value={formData.zip}
                                    name="zip"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    placeholder='Address'
                                    value={formData.address}
                                    name="address"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    placeholder='Email'
                                    value={formData.email}
                                    name="email"
                                    onChange={handleFieldChange}
                                    type="email"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Fax</Form.Label>
                                <Form.Control
                                    placeholder='Fax'
                                    value={formData.fax}
                                    name="fax"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col>
                            <Form.Check
                                type='switch'
                                name="isactive"
                                id='isactive'
                                checked={
                                    formData.isactive
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        isactive: e.target.checked
                                    })
                                }
                                label='IsActive'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    size="sm"
                                    className="w-100 mt-1"
                                    disabled={!formData.firstname || !formData.lastname || !formData.phone || !formData.speciality}>
                                    {locationData != null ? "Edit" : "Add"}
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <HcpModal show={showModal} setShow={setShowModal} handleHcpModalValue={handleHcpModalValue} />
        </Form>
    );
};

export default AddEditHcpForm;
