import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react'
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import AddFacilityStaff from '../add-facility-staff/AddFacilityStaff';

const NavItem = ({ item, classNameCustom }) => {
  return (
    console.log(classNameCustom),
    <Nav.Item as="li" className={`${classNameCustom}`}>
      <Nav.Link
        className={`mb-0 py-3 px-1 cursor-pointer`}
        eventKey={item.toLowerCase().replaceAll(" ", "-")}>
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

const ViewFacilityStaffProfileBody = (props) => {
  const [navItems, setNavItems] = useState(['Edit-Profile', 'Demo']);
  const data = props.data;

  return (
    <Card className="mt-6">
      <Tab.Container id="audience-tab" defaultActiveKey="edit-profile">
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="p-0 border-bottom patientInfoTabs"
        >
          <Nav
            as="ul"
            className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
          >
            {navItems.map(item => (
              <NavItem classNameCustom="col text-center" item={item} key={item} />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          <Row className="g-1">
            <Col xxl={12}>
              <Tab.Content>
              <Tab.Pane unmountOnExit eventKey="edit-profile">
                <AddFacilityStaff dataObj = {data}/>
              </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Card.Body>
      </Tab.Container>
    </Card>
  )
}

export default ViewFacilityStaffProfileBody