import React, { useState } from 'react';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

const MedAmountTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'amount',
            header: 'Amount',
            meta: {
                headerProps: { className: 'pe-1 col-lg-4 text-start text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updatedate',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.amount + "," + original.is_active + "," + original.medamount_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [medAmount, setMedAmount] = useState([]);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditMedAmountModal = (amountValue, isActive, amountId) => {
    //     //console.log("Trager" + event.target.id);
    //     //formName = event.target.id;
    //     //setFormNameModal(event.target.id);
    //     localStorage.setItem("amountValue", amountValue);
    //     localStorage.setItem("amountId", amountId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchMedAmount() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/drugs/get-drug-amount", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const drugAmtData = data.data

            if (data.status) {
                drugAmtData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            amount: value.amount,
                            medamount_id: value.id,
                            is_active: value.is_active// == 1 ? 0 : 1
                            // updated_by: value.updated_by,
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });

                setMedAmount(results);
            }
            else {
                toast.info("No MedAmount found in this list");
            }
        }

        fetchMedAmount();

        async function refreshMedAmtDetails() {
            fetchMedAmount();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshMedAmtDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: medAmount,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle "//text-center
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <EditMedAmountModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default MedAmountTable;
