import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

const DrugsNoteModal = ({ show, setShow, docType, patientId, cycleDetailId }) => {

    const [patientName, setPatientName] = useState("");
    //const [patientId, setPatientId] = useState("");
    const [drugs, setDrugsData] = useState([]);
    const [docNote, setDocNote] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
    }, []);

    const handleSave = async (e) => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let drugsGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-prescriptions`;
        const fileData = new FormData();
        fileData.append('file', selectedFile, selectedFile.name);

        //console.log("columns" + tableOpData);

        let drugsGridData = []

        let singlerowitem =
        {
            "cycle_detail_id": cycleDetailId,
            "change_note": docNote,
            "change_document": fileData
        }
        drugsGridData.push(singlerowitem);

        let finalDrugsData = {
            "cycle_patient_id": patientId,
            "prescriptions": drugsGridData
        }

        //console.log("drugsData " + JSON.stringify(finalDrugsData));

        const { data } = await axios.post(drugsGridUrl, finalDrugsData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        //console.log("data " + JSON.stringify(data));

        if (Object.keys(data).length > 0) {
            toast.success("Drug Details added successfully");
            setShow(false);
        }
        else {
            toast.error("Error occured while filling Drug Details ");
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    {docType == 1 && (
                        <p>D/C document</p>
                    )}
                    {docType == 2 && (
                        <p>Label change document</p>
                    )}
                    {docType == 3 && (
                        <p>Add hold note</p>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary">
                <Row>
                    <Row>
                        <Col xs="12">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Add Note</Form.Label>
                                <Form.Control name="docnote" onChange={(e) => { setDocNote(e.target.value) }} as="textarea" rows={3} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col>
                            {!(docType == 3) && (
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Document Upload</Form.Label>
                                    <Form.Control type="file" onChange={handleFileChange} />
                                </Form.Group>
                            )}
                        </Col>
                    </Row>
                </Row>
                <br />
                <Row>
                    <Col xs="8"></Col>
                    <Col xs="4">
                        <Button color="primary" className="w-100" onClick={handleSave}>Save</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

DrugsNoteModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default DrugsNoteModal;

