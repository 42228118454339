import React from 'react';
import { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import axios from "axios";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FacilityBasicInformation from './FacilityBasicInformation';
import { useNavigate, useLocation } from "react-router-dom";
import FacilityFooter from './FacilityFooter';
import FacilityMedsInfo from './FacilityMedsInfo';
import FacilityWorkInfo from './FacilityWorkInfo';
import { toast } from 'react-toastify';
import paths, { rootPaths } from '../../../../routes/paths';
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';
import MaskedInput from 'react-text-mask';

const AddFacilityStaff = (props) => {

  let initialFormData = {
    fname: '',
    lname: '',
    mobile: '',
    zip: '',
    city: '',
    state: '',
    address: '',
    email: '',
    designation: '',
    fax: '',
    providername: '',
    username: '',
    role: '',
    facility: '',
    password: '',
    cnfpassword: '',
    group : ''
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [options, setOptions] = useState([]);
  const [stateoptions, setStateOptions] = useState([]);
  const [roleoptions, setRoleOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [programdisable, setProgramDisabled] = useState(false);
  const [facilityvalue, setFacilityValue] = useState("");
  const locationData = props.dataObj

  useEffect(() => {
    async function fetchProviderData() {
      let currentRoleId = locationData.role_id;
      let results = JSON.parse(localStorage.getItem("facilitiesData"));
      let groupResults = JSON.parse(localStorage.getItem("groupsData")) ? JSON.parse(localStorage.getItem("groupsData")) : [];

      setStateOptions([
        { key: 'Select a state', value: '' },
        ...mapYourMedsConstant.STATE_VALUES
      ])

      setOptions([
        { key: 'Select a facility', value: '' },
        ...results
      ]);

      setGroupOptions([
        { key: 'Select a group', value: '' },
        ...groupResults
      ])

      let roleList = [];

      //console.log("currentRoleId " + currentRoleId)

      if (currentRoleId == 2) {
        roleList = [
          [
            { key: "Pharmacy Admin", value: 2 },
            { key: "Pharmacy Staff", value: 3 },
            { key: "Group Admin", value: 4 },
            { key: "Facility Staff", value: 5 }
          ]
        ]
      }
      else if (currentRoleId == 3) {
        roleList = [
          [
            { key: "Pharmacy Staff", value: 3 },
            { key: "Group Admin", value: 4 },
            { key: "Facility Staff", value: 5 }
          ]
        ]
      }
      else if (currentRoleId == 4) {
        roleList = [
          { key: "Group Admin", value: 4 },
          { key: "Facility Staff", value: 5 }
        ]
      }
      // else if (currentRoleId == 5) {
      //   roleList = [
      //     { key: "Facility Staff", value: 5 }
      //   ]
      // }
      else {
        roleList = [
          { key: "Pharmacy Admin", value: 2 },
          { key: "Pharmacy Staff", value: 3 },
          { key: "Group Admin", value: 4 },
          { key: "Facility Staff", value: 5 }
        ]
      }

      setRoleOptions([
        { key: 'Select a Role', value: '' },
        ...roleList
      ]);

      if (locationData != null) {
        let value = locationData;   
            //groupId = value.group_id;
            setFormData({
              fname: value.first_name,
              lname: value.last_name,
              mobile: value.phone,
              zip: value.zip,
              city: value.city,
              state: value.state,
              address: value.address,
              email: value.email,
              designation: '',
              fax: value.fax,
              providername: '',
              username: value.username,
              role: value.role_id,
              facility: value.facility_id,
              password: value.password,
              cnfpassword: value.password,
              group : value.group
            });

            //localStorage.setItem("groupId", groupId);

          
        
      }
    }

    // Trigger the fetch
    fetchProviderData();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    let facilityStaffData = locationData != null ?
      {
        "id": locationData.id,
        "username": formData.username,
        "password": formData.password,
        "first_name": formData.fname,
        "last_name": formData.lname,
        "email": formData.email,
        "role_id": formData.role,
        "group_id": localStorage.getItem("groupId"),
        "facilities": facilityvalue,//"{" + facilityvalue.toString() + "}",
        "phone": formData.phone,
        "fax": formData.fax,
        "city": formData.city,
        "state": formData.state,
        "zip": formData.zip
      } :
      {
        "username": formData.username,
        "password": formData.password,
        "first_name": formData.fname,
        "last_name": formData.lname,
        "email": formData.email,
        "role_id": formData.role,
        "group_id": localStorage.getItem("groupId"),
        "facilities": facilityvalue,//"{" + facilityvalue.toString() + "}",
        "phone": formData.phone,
        "fax": formData.fax,
        "city": formData.city,
        "state": formData.state,
        "zip": formData.zip
      };

    let tokenStr = localStorage.getItem("accessToken");

    console.log("userData " + JSON.stringify(facilityStaffData));

    let facilityStaffUrl = locationData != null ? `${process.env.REACT_APP_SERVER_URL}/users/update` : `${process.env.REACT_APP_SERVER_URL}/users/create`;

    const { data } = await axios.post(facilityStaffUrl, facilityStaffData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

    console.log("data " + JSON.stringify(data));

    if (data.message == "User created successfully") {
      toast.success("User added successfully");
      navigate(paths.viewFacility);
    }
    else {
      toast.error(data.message);
    }

    // fetch(`${process.env.REACT_APP_SERVER_URL}` + "/users/create",
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenStr}` },
    //     body: userData
    //   })
    //   .then(response => response.json())
    //   .then(function (data) {
    //     const items = data;
    //     console.log("AddFacility " + items.message);
    //     if (items.message == "User created successfully") {
    //       toast.success('User added successfully', {
    //         theme: 'colored'
    //       });
    //       navigate(paths.viewFacility);
    //     }
    //     else {
    //       toast.error('Unable to submit User', {
    //         theme: 'colored'
    //       });
    //     }
    //   })

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleRoleFieldChange = e => {
    //console.log("tager " + e.target.value)
    if (e.target.value == 4)
      setProgramDisabled(true);
    else
      setProgramDisabled(false);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFacilityFieldChange = (e) => {
    //console.log("Target " + Array.from(e.target.selectedOptions, option => option.value));
    setFacilityValue(Array.from(e.target.selectedOptions, option => option.value));
  };

  return (
    <Form onSubmit={handleSubmit} name="facilityform">
      <Row className="g-3">
        <Col lg={8}>
          <Card className="mb-2">
            <Card.Header as="h6" className="bg-body-tertiary">
              Personal Information
            </Card.Header>
            <Card.Body>
              <Row className="gx-2 gy-3">
                <Col md="6">
                  <Form.Group>
                    <Form.Label className="required">First Name</Form.Label>
                    <Form.Control
                      placeholder='First Name'
                      value={formData.fname}
                      name="fname"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label className="required">Last Name</Form.Label>
                    <Form.Control
                      placeholder='Last Name'
                      value={formData.lname}
                      name="lname"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Mobile</Form.Label>
                    <MaskedInput
                      mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      className="form-control form-control-sm"
                      guide={false}
                      placeholder="+1 (XXX) XXX-XXXX"
                      onChange={handleFieldChange}
                      size="sm"
                      name="mobile"
                      value={formData.mobile}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      placeholder='Zip'
                      value={formData.zip}
                      name="zip"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="6">
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      placeholder='City'
                      value={formData.city}
                      name="city"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Select
                      name="state"
                      onChange={handleRoleFieldChange}
                      size="sm"
                    //disabled={watch("role") == 4 ? true : false}
                    >
                      {stateoptions.map((stateoption) => {
                        let isStateSelected = false;
                        if (locationData != null) {
                          if (stateoption.value == formData.state) {
                            isStateSelected = "selected";
                          }
                        }
                        return (
                          <option key={stateoption.value} value={stateoption.value} selected={isStateSelected}>
                            {stateoption.key}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="12">
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      placeholder='Address'
                      value={formData.address}
                      name="address"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-2">
            <Card.Header as="h6" className="bg-body-tertiary">
              Work Information
            </Card.Header>
            <Card.Body>
              <Row className="gx-2 gy-3">
                <Col md="12">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      placeholder='Email'
                      value={formData.email}
                      name="email"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="12">
                  <Form.Group>
                    <Form.Label className="required">Designation:</Form.Label>
                    <Form.Select
                      onChange={handleFieldChange}
                      size="sm"
                      name="designation"
                    >
                      <option value="">Select</option>
                      <option value="lpn">LPN</option>
                      <option value="map"> MAP</option>
                      <option value="other"> OTHER</option>
                      <option value="rn">RN</option>
                      <option value="rs"> RS</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="12">
                  <Form.Group>
                    <Form.Label>Fax</Form.Label>
                    <Form.Control
                      placeholder='Fax'
                      value={formData.fax}
                      name="fax"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="12">
                  <Form.Group>
                    <Form.Label>Provider Name</Form.Label>
                    <Form.Control
                      placeholder='Provider Name'
                      value={formData.providername}
                      name="providername"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card className="mb-2">
            <Card.Header as="h6" className="bg-body-tertiary">
              Access Information
            </Card.Header>
            <Card.Body>
              <Row className="gx-2 gy-3">
                <Col md="12">
                  <Form.Group>
                    <Form.Label className="required">User Name</Form.Label>
                    <Form.Control
                      placeholder='User Name'
                      value={formData.username}
                      name="username"
                      onChange={handleFieldChange}
                      type="text"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="6">
                  <Form.Group>
                    <Form.Label className="required">Role</Form.Label>
                    <Form.Select
                      name="role"
                      onChange={handleRoleFieldChange}
                      size="sm"
                    //disabled={watch("role") == 4 ? true : false}
                    >
                      {roleoptions.map((roleoption) => {
                        let isRoleSelected = false;
                        if (locationData != null) {
                          if (roleoption.value == formData.role) {
                            isRoleSelected = "selected";
                          }
                        }
                        return (
                          <option key={roleoption.value} value={roleoption.value} selected={isRoleSelected}>
                            {roleoption.key}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Program (Press Ctrl to select multiple facilities)</Form.Label>
                    <Form.Select
                      multiple
                      disabled={programdisable}
                      name="facility"
                      onChange={handleFacilityFieldChange}
                      size="sm"
                    >
                      {options.map((option) => {
                        let isFacilitySelected = false;
                        if (locationData != null) {
                          if (option.value == formData.facility) {
                            isFacilitySelected = "selected";
                          }
                        }
                        return (
                          <option key={option.value} value={option.value} selected={isFacilitySelected}>
                            {option.key}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Group</Form.Label>
                    <Form.Select
                      name="group"
                      onChange={handleFieldChange}
                      size="sm"
                    >
                      {groupOptions.map((groupoption) => {
                        let isGroupSelected = false;
                        if (locationData != null) {
                          if (groupoption.value == formData.role) {
                            isGroupSelected = "selected";
                          }
                        }
                        return (
                          <option key={groupoption.value} value={groupoption.value} selected={isGroupSelected}>
                            {groupoption.key}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-2 gy-3">
                <Col md="6">
                  <Form.Group>
                    <Form.Label className="required">Password</Form.Label>
                    <Form.Control
                      placeholder='Password'
                      value={formData.password}
                      name="password"
                      onChange={handleFieldChange}
                      type="password"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label className="required">Confirm Password</Form.Label>
                    <Form.Control
                      placeholder='Confirm Password'
                      value={formData.cnfpassword}
                      name="cnfpassword"
                      onChange={handleFieldChange}
                      type="password"
                      size="sm"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Row className="flex-between-center">
            <Col></Col>
            <Col xs="auto">
              <Button variant="primary"
                type="submit"
                disabled={!formData.password || !formData.cnfpassword || !formData.role
                  || !formData.username || !formData.fname || !formData.lname || !formData.designation}
              >
                {locationData != null ? mapYourMedsConstant.UPDATE_ACTION : mapYourMedsConstant.SAVE_ACTION}
              </Button>
            </Col>
            <Col>
              <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default AddFacilityStaff;
