import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Col, Form, Row, Button } from 'react-bootstrap';

const cycleCheckListItems = [
  {
    id: 1,
    label: 'Please select medchanges first',
    icon: 'check'
  },
  {
    id: 2,
    label: 'Must Approve census',
    icon: 'check'
  },
  { id: 3, label: 'Pick date and time for next delivery', icon: 'check' },
  {
    id: 4,
    label: 'For any changes 10 day prior to cycle, please call 781-966-2700',
    icon: 'check'
  }
];

const CycleChecklist = ({ bodyClassName }) => {

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Cycle Checklist"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body
        as={Flex}
        direction="column"
        alignItems="between"
        className="h-100"
      >
        <ul className="fa-ul ms-2 ps-x1 mb-2">
          {cycleCheckListItems.map(item => (
            <li key={item.id}>
              <h6 className="text-700">
                <FontAwesomeIcon
                  icon={item.icon}
                  className="fa-li text-success"
                />
                {item.label}
              </h6>
            </li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

CycleChecklist.propTypes = {
  bodyClassName: PropTypes.string
};

export default CycleChecklist;
