import Flex from 'components/common/Flex';
import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';


const FacilityLookUpHeader = (props) => {
    const { data } = props;
    return (
        <Card>
            <Card.Header className="pb-0 pt-4">
                <Row>
                    <Col>
                        <h4 className="mb-1">
                            {data?.name}
                        </h4>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Col lg={4} className="ps-2 ps-lg-3 me-0 ms-auto">
                    <Flex alignItems="center" className="mb-2">
                        <div className="flex-1">
                            <h6 className="mb-0">No of Facility: {""} </h6>
                        </div>
                    </Flex>
                    <Flex alignItems="center" className="mb-2">
                        <div className="flex-1">
                            <h6 className="mb-0"> Active Packages: {[
                                data?.is_peltask_active && "Peltask",
                                data?.is_mym_active && "MapYourMeds",
                                data?.is_mobile_app_active && "Pelpak"
                            ].filter(Boolean).join(", ")}</h6>
                        </div>
                    </Flex>
                </Col>
            </Card.Body>
        </Card>
    );
};

export default FacilityLookUpHeader;
