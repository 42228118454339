import React from 'react';

import { Card, Col, Form, Row, Button, InputGroup, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import paths from 'routes/paths';
import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import PrescriptionModal from './PrescriptionModal';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import MaskedInput from 'react-text-mask';
import moment from "moment";
import { toast } from 'react-toastify';


const AddEditPrescription = () => {

    let initialFormData = {
        facilityname: '',
        individual: '',
        hcp: '',
        drugid: '',
        pres_drug: '',
        drug_form: '',
        ndc: '',
        brand: '',
        generic: '',
        amt: '',
        dose: '',
        freq: '',
        route: '',
        startdate: '',
        stopdate: '',
        //expirydate: '',
        rxnumber: '',
        rsnpres: '',
        splins: '',
        callhcp: '',
        pharma: '',
        medgrp: '',
        med_status: '',
        earlyLateTime: '',
        exceeddate: '',
        timebetweenmeds: '',
        is_medsheet: false,
        is_treatment: false,
        is_physpres: false,
        is_vital: false,
        is_prnmedication: true,
        time1: '',
        time2: '',
        time3: '',
        time4: '',
        time5: '',
        time6: '',
        time7: '',
        time8: '',
        patient_name : '',
        hcp_name : '',

    };

    const [formData, setFormData] = useState(initialFormData);

    const [checked, setChecked] = useState(true);
    const [edittimeclicked, setEditTimeClicked] = useState(false);

    const [startdate, setStartDate] = useState(null);
    const [stopdate, setStopDate] = useState(null);
    //const [expirydate, setExpiryDate] = useState(null);
    // const [earlylatedate, setEarlyLateDate] = useState(null);
    // const [timebetweenmeds, setTimeBetweenMeds] = useState(null);

    const [time1options, setTime1] = useState([]);
    const [time2options, setTime2] = useState([]);
    const [time3options, setTime3] = useState([]);
    const [time4options, setTime4] = useState([]);
    const [time5options, setTime5] = useState([]);
    const [time6options, setTime6] = useState([]);
    const [time7options, setTime7] = useState([]);
    const [time8options, setTime8] = useState([]);

    const [facilityoptions, setFacilityOptions] = useState([]);
    // const [patientoptions, setPatientOptions] = useState([]);
    const [hcpoptions, setHcpOptions] = useState([]);
    const [presdrugoptions, setPresDrugOptions] = useState([]);
    const [amtOptions, setAmtOptions] = React.useState([]);
    const [freqOptions, setFreqOptions] = React.useState([]);
    const [routeOptions, setRouteOptions] = React.useState([]);
    const [rsnOptions, setRsnOptions] = React.useState([]);
    const [splInsOptions, setSplinsOptions] = React.useState([]);
    const [pharmacyOptions, setPharmacyOptions] = React.useState([]);
    const [medgroupOptions, setMedGroupOptions] = React.useState([]);
    const [facilityName, setFacilityName] = useState('');
    const [earlyLateTime, setEarlyLateTime] = useState("00:00");
    const [timeBetweenOtherMed, setTimeBetweenOtherMed] = useState("00:00");
    const [showDropdown, setShowDropdown] = useState(false);
    const [showTimeBetweenOtherMedDropdown, setShowTimeBetweenOtherMedDropdown] = useState(false);

    const {id} = useParams();
    const locationData = {
        orderid : id
    }
    const location = useLocation();
    const props = location.state;

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const handleTimeChange = (type, direction) => {
        let [hours, minutes] = earlyLateTime.split(":").map(Number);
        if (type === "hours") {
          hours = direction === "up" ? (hours + 1) % 24 : (hours - 1 + 24) % 24;
        } else {
          minutes = direction === "up" ? (minutes + 1) % 60 : (minutes - 1 + 60) % 60;
        }
        setEarlyLateTime(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`);
      };

      const handleTimeBetweenOtherMeds = (type, direction) => {
        let [hours, minutes] = timeBetweenOtherMed.split(":").map(Number);
        if (type === "hours") {
          hours = direction === "up" ? (hours + 1) % 24 : (hours - 1 + 24) % 24;
        } else {
          minutes = direction === "up" ? (minutes + 1) % 60 : (minutes - 1 + 60) % 60;
        }
        setTimeBetweenOtherMed(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`);
      };

      const handleInputClick = () => {
        setShowDropdown(!showDropdown);
      };

      const handleTimeBetweenOtherMedInputClick = () => {
        setShowTimeBetweenOtherMedDropdown(!showTimeBetweenOtherMedDropdown);
      };
    
      const handleDropdownClose = () => {
        setShowDropdown(false);
      };

      const handleTimeBetweenOtherMedDropdownClose = () => {
        setShowTimeBetweenOtherMedDropdown(false);
      };

    useEffect(() => {
        populateData();
    }, []);

    async function populateData() {

        let tokenStr = localStorage.getItem("accessToken");
        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        //console.log("Facility Points " + results);

        setFacilityOptions([
            { key: 'Select a facility', value: '' },
            ...results
        ]);


        let getAllItems = locationData.orderid != null ?
            {
                prescription: {
                    id: locationData.orderid
                },
                //drug: {},
                amount: {},
                frequency: {},
                reason: {},
                route: {},
                special_instruction: {},
                pharmacy_store: {},
                medgroup: {},
                prescription_hour: {}
            } :
            {
                //drug: {},
                amount: {},
                frequency: {},
                reason: {},
                route: {},
                special_instruction: {},
                pharmacy_store: {},
                medgroup: {},
                prescription_hour: {}
            };

        await axios.post(`${process.env.REACT_APP_SERVER_URL}/prescriptions/fetch-multiple`, getAllItems, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                /**********************DRUGS*****************************/

                let drugResults = JSON.parse(localStorage.getItem("drugData"));

                //console.log("Major Data " + JSON.stringify(res.data));

                setPresDrugOptions([
                    { key: 'Select a Drug', value: '' },
                    ...drugResults
                ]);

                /**********************AMOUNT*****************************/

                let objAmountData = res.data.output.data["amount"];
                let amtResults = [];

                objAmountData.forEach((value) => {
                    if (value.amount != null && value.amount != "") {
                        amtResults.push({
                            key: value.amount,
                            value: value.id,
                        });
                    }
                });

                setAmtOptions([
                    { key: 'Select Amount', value: '' },
                    ...amtResults
                ]);

                /**********************FREQUENCY*****************************/

                let objFreqData = res.data.output.data["frequency"];
                let freqResults = [];

                objFreqData.forEach((value) => {
                    if (value.name != null && value.name != "") {
                        freqResults.push({
                            key: value.name,
                            value: value.id,
                        });
                    }
                });

                setFreqOptions([
                    { key: 'Select Frequency', value: '' },
                    ...freqResults
                ]);

                /**********************ROUTE*****************************/

                let objRouteData = res.data.output.data["route"];
                let routeResults = [];

                objRouteData.forEach((value) => {
                    if (value.route != null && value.route != "") {
                        routeResults.push({
                            key: value.route,
                            value: value.id,
                        });
                    }
                });

                setRouteOptions([
                    { key: 'Select Route', value: '' },
                    ...routeResults
                ]);

                /**********************REASON*****************************/

                let objReasonData = res.data.output.data["reason"];
                let reasonResults = [];

                objReasonData.forEach((value) => {

                    if (value.reason != null && value.reason != "") {
                        reasonResults.push({
                            key: value.reason,
                            value: value.id,
                        });
                    }
                });

                setRsnOptions([
                    { key: 'Select Reason', value: '' },
                    ...reasonResults
                ]);

                /**********************INSTRUCTIONS*****************************/

                let objInsData = res.data.output.data["special_instruction"];
                let insResults = [];

                objInsData.forEach((value) => {

                    if (value.instruction != null && value.instruction != "") {
                        insResults.push({
                            key: value.instruction,
                            value: value.id,
                        });
                    }
                });

                setSplinsOptions([
                    { key: 'Select Special Instruction', value: '' },
                    ...insResults
                ]);

                /**********************PHARMACY STORE*****************************/

                let objPharmacyData = res.data.output.data["pharmacy_store"];
                let pharmacyResults = [];

                objPharmacyData.forEach((value) => {

                    if (value.pharmacy_store_name != null && value.pharmacy_store_name != "") {
                        pharmacyResults.push({
                            key: value.pharmacy_store_name,
                            value: value.id,
                        });
                    }
                });

                setPharmacyOptions([
                    { key: 'Select Pharmacy', value: '' },
                    ...pharmacyResults
                ]);

                /**********************MED GROUP*****************************/

                let objMedgroupData = res.data.output.data["medgroup"];
                let medgroupResults = [];

                objMedgroupData.forEach((value) => {

                    if (value.name != null && value.name != "") {
                        medgroupResults.push({
                            key: value.name,
                            value: value.id,
                        });
                    }
                });

                setMedGroupOptions([
                    { key: 'Select MedGroup', value: '' },
                    ...medgroupResults
                ]);

                /**********************TIME*****************************/

                let objHrData = res.data.output.data["prescription_hour"];
                let hrResults = [];

                objHrData.forEach((value) => {

                    if (value.time != null && value.time != "") {
                        hrResults.push({
                            key: value.time,
                            value: value.id,
                        });
                    }
                });

                setTime1([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime2([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime3([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime4([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime5([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime6([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime7([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime8([{ key: 'Select Time', value: '' }, ...hrResults]);


                /**********************EDIT PRESCRIPTION*****************************/
                if (locationData.orderid != null) {
                    let objPrescriptionData = res.data.output.data["prescription"];
                    let facilityId = objPrescriptionData[0].facility_id
                    setFacilityName(results.filter(value => value.value == facilityId))

                    /**********************RX Number*****************************/
                    let prescriptionResults = JSON.parse(localStorage.getItem("prescriptionObjData"));
                    let rx_number = "";

                    prescriptionResults.forEach((value) => {

                        if (locationData.orderid == value.id) {
                            rx_number = value.pharmacy_order_id;
                        }

                    });


                    /**********************RX Number*****************************/
                    objPrescriptionData.forEach((singlePrescription) => {

                        //console.log("singlePrescription " + JSON.stringify(singlePrescription));
                        // fetchPatientData(singlePrescription.facility_id);
                        if(!locationData.orderid){
                            fetchHcpData(singlePrescription.facility_id);
                        }

                        drugResults.forEach((singleDrug) => {

                            let singleDrugValueArr = singleDrug.value.split("|");

                            if (singleDrugValueArr[0] == singlePrescription.drug_id) {

                                singlePrescription.drug_id = singleDrugValueArr[0];
                                singlePrescription.drug = singleDrugValueArr[1];
                                singlePrescription.brand_drug = singleDrugValueArr[2];
                                singlePrescription.drug_ndc = singleDrugValueArr[3];
                                singlePrescription.drug_form = singleDrugValueArr[4];
                                singlePrescription.generic = singleDrugValueArr[5];
                            }

                            //console.log("singlePrescription " + singlePrescription.drug_id + singlePrescription.drug + "|" + singlePrescription.brand_drug + "|" + singlePrescription.drug_ndc + "|" + singlePrescription.drug_form + "|" + singlePrescription.generic,);

                        });

                        setFormData({
                            facilityname: singlePrescription.facility_id,
                            individual: singlePrescription.patient_id,
                            hcp: singlePrescription.doctor_id,
                            pres_drug: singlePrescription.drug_id + "|" + singlePrescription.drug + "|" + singlePrescription.brand_drug + "|" + singlePrescription.drug_ndc + "|" + singlePrescription.drug_form + "|" + singlePrescription.generic,
                            drug_form: singlePrescription.drug_form,
                            ndc: singlePrescription.drug_ndc,
                            rxnumber: singlePrescription.pharmacy_order_id,
                            brand: singlePrescription.brand_drug,
                            generic: singlePrescription.generic,
                            dose: singlePrescription.dose,
                            callhcp: singlePrescription.remarks,
                            pharma: singlePrescription.pharmacy_store_id,
                            medgrp: singlePrescription.medgroup_id,
                            splins: singlePrescription.special_instruction_id,
                            route: singlePrescription.route_id,
                            freq: singlePrescription.frequency_id,
                            rsnpres: singlePrescription.reason_id,
                            amt: singlePrescription.amount_id,
                            med_status: singlePrescription.medication_independent_status,
                            exceeddate: singlePrescription.not_exceed,
                            is_medsheet: singlePrescription.mar_flag ? false : true,
                            is_treatment: singlePrescription.tar_flag ? false : true,
                            is_physpres: singlePrescription.po_flag ? false : true,
                            is_vital: singlePrescription.has_vitalsigns ? false : true,
                            is_prnmedication: singlePrescription.med_type == "P" ? true : false,
                            time1: singlePrescription.time_1,
                            time2: singlePrescription.time_2,
                            time3: singlePrescription.time_3,
                            time4: singlePrescription.time_4,
                            time5: singlePrescription.time_5,
                            time6: singlePrescription.time_6,
                            time7: singlePrescription.time_7,
                            time8: singlePrescription.time_8,
                            patient_name : (`${singlePrescription.patient_lastname},${singlePrescription.patient_firstname}`),
                            hcp_name : (`${singlePrescription.doctor_lastname},${singlePrescription.doctor_firstname}`)
                        });

                        setStartDate(singlePrescription.client_startdate != null ? moment(singlePrescription.client_startdate, 'YYYY-MM-DD').format('MM/DD/YY') : null);

                        setStopDate(singlePrescription.client_stopdate != null ? moment(singlePrescription.client_stopdate, 'YYYY-MM-DD').format('MM/DD/YY') : null);

                        //setExpiryDate(singlePrescription.pharmacy_expire_date != null ? moment(singlePrescription.pharmacy_expire_date, 'YYYY-MM-DD').format('MM/DD/YY') : null);

                        setEarlyLateTime(singlePrescription.early  != null ? singlePrescription.early : "00:00");
                        setTimeBetweenOtherMed(singlePrescription.time_between_meds != null ? singlePrescription.time_between_meds : "00:00");

                        setChecked(singlePrescription.med_type == "P" ? true : false);
                    });       

                };
            });

            
            // fetchPatientData(formData.facilityname);
            
            if(!locationData.orderid){fetchHcpData(formData.facilityname);}

    }

    // async function fetchPatientData(facilityid) {

    //     let tokenStr = localStorage.getItem("accessToken");
    //     const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-all-patients/" + facilityid, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    //     let results = []

    //     //console.log("Patient Data Status " + data.status);
    //     let objPatientData = data.patients.data;

    //     objPatientData.forEach((value) => {
    //         results.push({
    //             key: value.first_name + ", " + value.last_name,
    //             value: value.id
    //         });
    //     });

    //     setPatientOptions([
    //         ...results
    //     ])

    // }

    async function fetchHcpData(facilityid) {

        let tokenStr = localStorage.getItem("accessToken");
        //TODO confirm with SHANKS whether listing is based on facility id or not
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/doctors/list-doctors", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let results = []

        //console.log("Doctor Data Status " + data.status);
        let objPatientData = data.data;

        objPatientData.forEach((value) => {
            results.push({
                key: value.firstname + ", " + value.lastname,
                value: value.id
            });
        });

        setHcpOptions([
            ...results
        ])

    }

    // const handleFacilityFieldChange = e => {
    //     fetchPatientData(e.target.value);
    //     fetchHcpData(e.target.value);
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     });
    // }

    const handleDrugFieldChange = e => {
        let drugPres = e.target.value;
        let drugPresArr = drugPres.split("|");
        setFormData({
            ...formData,
            drugid: drugPresArr[0],
            pres_drug: drugPresArr[1],
            brand: drugPresArr[2],
            ndc: drugPresArr[3],
            drug_form: drugPresArr[4],
            generic: drugPresArr[5]
        });
    };

    const handlePrescriptionModal = (event) => {
        localStorage.setItem("prescriptionFormName", event.target.id);
        if (event.target.id == "btnfrequency") {
            localStorage.setItem("prescriptionModalFormValues", freqOptions);
        }
        setShowModal(true);
    };

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();

        if (e.target.name == "prescriptionform") {

            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsPrescriptionUrl = locationData.orderid != null ? `${process.env.REACT_APP_SERVER_URL}/prescriptions/edit-prescription` : `${process.env.REACT_APP_SERVER_URL}/prescriptions/add-prescriptions`;

            let amtManual = [];
            let freqManual = [];
            let routeManual = [];
            let rsnManual = [];
            let splInsManual = [];

            amtOptions.map((amtoption) => {

                if (amtoption.value == "-999") {
                    amtManual.push(amtoption.key);
                }
            });
            freqOptions.map((freqoption) => {

                if (freqoption.value == "-999") {
                    freqManual.push(freqoption.key);
                }
            });
            routeOptions.map((routeoption) => {

                if (routeoption.value == "-999") {
                    routeManual.push(routeoption.key);
                }
            });
            rsnOptions.map((rsnoption) => {

                if (rsnoption.value == "-999") {
                    rsnManual.push(rsnoption.key);
                }
            });
            splInsOptions.map((splinsoption) => {

                if (splinsoption.value == "-999") {
                    splInsManual.push(splinsoption.key);
                }
            });

            let pelmedsPrescriptionData = locationData.orderid != null ?
                {
                    id: locationData.orderid,
                    group_id: localStorage.getItem("groupId"),
                    facility_id: formData.facilityname,
                    patient_id: formData.individual,
                    doctor_id: formData.hcp,
                    drug_id: formData.drugid,
                    drug: formData.pres_drug.split("|")[1],
                    drug_form: formData.drug_form,
                    ndc: formData.ndc,
                    brand_drug: formData.brand,
                    generic: formData.generic,
                    dose: formData.dose,
                    amount_id: formData.amt,
                    amount_manual: amtManual.toString(),
                    frequency_id: formData.freq,
                    frequency_manual: freqManual.toString(),
                    route_id: formData.route,
                    route_manual: routeManual.toString(),
                    client_startdate: startdate != null ? moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                    client_stopdate: stopdate != null ? moment(stopdate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                    //pharmacy_expire_date: expirydate != null ? moment(expirydate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                    reason_id: formData.rsnpres,
                    reason_manual: rsnManual.toString(),
                    special_instruction_id: formData.splins,
                    special_instruction_manual: splInsManual.toString(),
                    remarks: formData.callhcp,
                    pharmacy_store_id: formData.pharma,
                    medgroup_id: formData.medgrp,
                    medication_independent_status: formData.med_status,
                    early: earlyLateTime != null ? moment(earlyLateTime, 'hh:mm').format('hh:mm') : null,
                    //not_exceed: exceeddate != null ? moment(exceeddate, 'hh:mm').format('hh:mm') : null,
                    not_exceed: formData.exceeddate,
                    //time_between_meds: formData.timebetweenmeds,
                    time_between_meds: timeBetweenOtherMed != null ? moment(timeBetweenOtherMed, 'hh:mm').format('hh:mm') : null,
                    mar_flag: formData.is_medsheet ? 1 : 0,
                    tar_flag: formData.is_treatment ? 1 : 0,
                    has_vitalsigns: formData.is_vital ? 1 : 0,
                    po_flag: formData.is_physpres ? 1 : 0,
                    med_type: formData.is_prnmedication ? "P" : "P", //Todo why MedType is P
                    time_1: formData.time1,
                    time_2: formData.time2,
                    time_3: formData.time3,
                    time_4: formData.time4,
                    time_5: formData.time5,
                    time_6: formData.time6,
                    time_7: formData.time7,
                    time_8: formData.time8,
                } :
                {
                    group_id: localStorage.getItem("groupId"),
                    facility_id: formData.facilityname,
                    patient_id: formData.individual,
                    doctor_id: formData.hcp,
                    drug_id: formData.drugid,
                    drug: formData.pres_drug.split("|")[1],
                    drug_form: formData.drug_form,
                    ndc: formData.ndc,
                    brand_drug: formData.brand,
                    generic: formData.generic,
                    dose: formData.dose,
                    amount_id: formData.amt,
                    amount_manual: amtManual.toString(),
                    frequency_id: formData.freq,
                    frequency_manual: freqManual.toString(),
                    route_id: formData.route,
                    route_manual: routeManual.toString(),
                    client_startdate: startdate != null ? moment(startdate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
                    client_stopdate: stopdate != null ? moment(stopdate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
                    //pharmacy_expire_date: expirydate != null ? moment(expirydate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
                    reason_id: formData.rsnpres,
                    reason_manual: rsnManual.toString(),
                    special_instruction_id: formData.splins,
                    special_instruction_manual: splInsManual.toString(),
                    remarks: formData.callhcp,
                    pharmacy_store_id: formData.pharma,
                    medgroup_id: formData.medgrp,
                    medication_independent_status: formData.med_status,
                    early: earlyLateTime != null ? moment(earlyLateTime, 'hh:mm').format('hh:mm') : null,
                    //not_exceed: exceeddate != null ? moment(exceeddate, 'hh:mm').format('hh:mm') : null,
                    //time_between_meds: formData.timebetweenmeds,
                    not_exceed: formData.exceeddate,
                    time_between_meds: timeBetweenOtherMed != null ? moment(timeBetweenOtherMed, 'hh:mm').format('hh:mm') : null,
                    mar_flag: formData.is_medsheet ? 1 : 0,
                    tar_flag: formData.is_treatment ? 1 : 0,
                    has_vitalsigns: formData.is_vital ? 1 : 0,
                    po_flag: formData.is_physpres ? 1 : 0,
                    med_type: formData.is_prnmedication ? "P" : "P", //Todo why MedType is P
                    time_1: formData.time1,
                    time_2: formData.time2,
                    time_3: formData.time3,
                    time_4: formData.time4,
                    time_5: formData.time5,
                    time_6: formData.time6,
                    time_7: formData.time7,
                    time_8: formData.time8,
                }

            //console.log("pelmedsPrescriptionData " + JSON.stringify(pelmedsPrescriptionData));

            const { data } = await axios.post(pelmedsPrescriptionUrl, pelmedsPrescriptionData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const patientDetails = paths.patientDetails;
            if (data.status && locationData.orderid == null) {
                toast.success("Prescription Details added successfully");
                navigate(`${patientDetails}/${formData.individual}`);
            }
            else if (data.status && locationData.orderid != null) {
                toast.success("Prescription Details updated successfully");
                navigate(`${patientDetails}/${formData.individual}`);
            }
            else {
                toast.info("Error while adding Prescription Details");
            }

            //if (locationData == null) {
            //    setFormData(initialFormData);
            //    formRef.current.reset();
            //}
            //console.log("DataOutput " + JSON.stringify(data));

        }
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // const validateExpiryDate = expirydate => {
    //     //console.log("expiry date" + expirydate);
    //     //console.log("start date" + startdate);

    //     if (moment(expirydate) > moment(stopdate)) {
    //         setExpiryDate(expirydate)
    //     }
    //     else {
    //         toast.info("Expiry date should be greater than Start Date");
    //     }

    // }

    const handlePrnMedication = (e) => {
        //setChecked(!checked);
        console.log("\n\n\n===============\n\n\n", e.target.checked)
        setChecked(e.target.checked);
        setFormData({
            ...formData,
            is_prnmedication: e.target.checked
        });
    };

    const handleMoreTime = (e) => {
        if (!edittimeclicked)
            setEditTimeClicked(true);
    };

    const handlePrescriptionModalValue = (modalvalue) => {
        let formName = localStorage.getItem("prescriptionFormName");

        if (formName == "btnsplins") {
            let isDuplicate = handleDrpDuplicates(splInsOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate) {
                let updatedInsOptions = splInsOptions.filter((singleoption) => singleoption.value != -999);
                updatedInsOptions.push({ key: modalvalue, value: -999 });
                setSplinsOptions(updatedInsOptions);
            }
        }
        else if (formName == "btnrsnpres") {
            let isDuplicate = handleDrpDuplicates(rsnOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate) {
                let updatedRsnOptions = rsnOptions.filter((singleoption) => singleoption.value != -999);
                updatedRsnOptions.push({ key: modalvalue, value: -999 });
                setRsnOptions(updatedRsnOptions);
            }
        }
        else if (formName == "btnroute") {
            let isDuplicate = handleDrpDuplicates(routeOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate) {
                let updatedRouteOptions = routeOptions.filter((singleoption) => singleoption.value != -999);
                updatedRouteOptions.push({ key: modalvalue, value: -999 });
                setRouteOptions(updatedRouteOptions);
            }
        }
        else if (formName == "btnfrequency") {
            let isDuplicate = handleDrpDuplicates(freqOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate) {
                let updatedFreqOptions = freqOptions.filter((singleoption) => singleoption.value != -999);
                updatedFreqOptions.push({ key: modalvalue, value: -999 });
                setFreqOptions(updatedFreqOptions);
            }
        }
        else if (formName == "btnamt") {
            let isDuplicate = handleDrpDuplicates(amtOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate) {
                let updatedAmtOptions = amtOptions.filter((singleoption) => singleoption.value != -999);
                updatedAmtOptions.push({ key: modalvalue, value: -999 });
                setAmtOptions(updatedAmtOptions);
            }
        }

        localStorage.setItem("prescriptionFormName", "");
        localStorage.setItem("prescriptionModalFormValues", "");

    };

    function handleDrpDuplicates(drpdwnOptions, modalvalue) {

        let isDuplicate = false;

        drpdwnOptions.map((singleoption) => {
            //console.log(singleoption.key + " && " + modalvalue);
            if (singleoption.key.toLowerCase() == modalvalue.toLowerCase()) {
                isDuplicate = true;
            }
        })

        return isDuplicate;
    }

    // function getSelectedKey(drpDwnOptions, value) {
    //     let drpKey = "";

    //     drpDwnOptions.map((singleoption) => {
    //         //console.log(singleoption.key + " && " + modalvalue);
    //         if (singleoption.value == value) {
    //             drpKey = singleoption.key;
    //         }
    //     })

    //     return drpKey;
    // }

    return (
        <Form onSubmit={handleSubmit} name="prescriptionform">
            <Card className="mb-3">
                <Card.Header as="h6" className="bg-body-tertiary">
                    <Row className="mb-2">
                        <Col xs="3">
                            {locationData.orderid != null ? "Edit" : "Add"} Prescription
                        </Col>
                        <Col xs="6"></Col>
                        <Col xs="3" className='text-end'>
                            {locationData.orderid != null ? "Prescription No.: " + locationData.orderid : ""}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Facility</Form.Label>
                                <Form.Control
                                    name="facilityname"
                                    
                                    size="sm"
                                    disabled="disabled"
                                    defaultValue={props.facilityname ? props.facilityname : facilityName[0]?.key}
                                    >
                                    {/* {facilityoptions.map((option) => {
                                        let isFacilitySelected = false;
                                        if (option.value == locationData.facilityid) {
                                            isFacilitySelected = "selected";
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isFacilitySelected} >
                                                {option.key}
                                            </option>
                                        );
                                    })} */}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {locationData.orderid != null && (
                            <Col>
                                <Form.Group>
                                    <Form.Label>Rx Number</Form.Label>
                                    <Form.Control
                                        placeholder='Rx Number'
                                        value={formData.rxnumber}
                                        name="rxnumber"
                                        onChange={handleFieldChange}
                                        type="text"
                                        disabled="disabled"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>)}
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Individual</Form.Label>
                                <Form.Control
                                    name="individual"
                                    onChange={handleFieldChange}
                                    size="sm"
                                    disabled="disabled"
                                    value={props.patientname ? props.patientname : formData.patient_name}>
                                    {/* <option key="Select Individual" value="">Select Individual</option>
                                    {patientoptions.map((option) => {
                                        var isPatientSelected = false;
                                        if (option.value == locationData.patientid) {
                                            isPatientSelected = "selected";
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isPatientSelected} >
                                                {option.key}
                                            </option>
                                        );
                                    })} */}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>HCP</Form.Label>
                                {locationData.orderid ?
                                <Form.Control
                                name="hcp"
                                size="sm"
                                disabled="disabled"
                                defaultValue={formData.hcp_name}
                                >
                                    
                                </Form.Control>
                                     : <Form.Select
                                     name="hcp"
                                     onChange={handleFieldChange}
                                     size="sm"
                                     disabled={locationData.orderid != null}>
                                     <option key="Select Individual" value="">Select HCP</option>
                                     {hcpoptions.map((option) => {
                                         var isHcpSelected = false;
                                         if (locationData.orderid != null) {
                                             if (option.value == formData.hcp) {
                                                 isHcpSelected = "selected";
                                             }
                                         }
                                         return (
                                             <option key={option.value} value={option.value} selected={isHcpSelected}>
                                                 {option.key}
                                             </option>
                                         );
                                     })}
                                 </Form.Select>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Prescribe Drug</Form.Label>
                                <Form.Select
                                    name="pres_drug"
                                    onChange={handleDrugFieldChange}
                                    size="sm"
                                    disabled={locationData.orderid != null}>
                                    {presdrugoptions.map((option) => {
                                        var isPresDrugSelected = false;
                                        if (locationData.orderid != null) {
                                            if (option.value == formData.pres_drug) {
                                                isPresDrugSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isPresDrugSelected}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Drug Form</Form.Label>
                                <Form.Control
                                    placeholder='Drug Form'
                                    value={formData.drug_form}
                                    name="drug_form"
                                    onChange={handleFieldChange}
                                    type="text"
                                    disabled="disabled"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>NDC</Form.Label>
                                <Form.Control
                                    placeholder='NDC'
                                    value={formData.ndc}
                                    name="ndc"
                                    onChange={handleFieldChange}
                                    type="text"
                                    disabled="disabled"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    placeholder='Brand'
                                    value={formData.brand}
                                    name="brand"
                                    onChange={handleFieldChange}
                                    type="text"
                                    disabled="disabled"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Generic</Form.Label>
                                <Form.Control
                                    placeholder='Generic'
                                    value={formData.generic}
                                    name="generic"
                                    onChange={handleFieldChange}
                                    type="text"
                                    disabled="disabled"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select
                                        name="amt"
                                        onChange={handleFieldChange}
                                        size="sm"
                                    >
                                        {amtOptions.map((amtoption) => {
                                            var isAmtSelected = false;
                                            if (locationData.orderid != null) {
                                                if (amtoption.value == formData.amt) {
                                                    isAmtSelected = "selected";
                                                }
                                            }

                                            if (amtoption.value == -999) {
                                                isAmtSelected = "selected";
                                            }

                                            return (
                                                <option key={amtoption.value} value={amtoption.value} selected={isAmtSelected}>
                                                    {amtoption.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Button variant="outline-secondary" size="sm" id="btnamt" onClick={handlePrescriptionModal}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Dose</Form.Label>
                                <Form.Control
                                    placeholder='Dose'
                                    value={formData.dose}
                                    name="dose"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row  className="mb-2">
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_prnmedication"
                                id='is_prnmedication'
                                checked={
                                    formData.is_prnmedication
                                }
                                label='Check this Box For PRN Medications'
                                className="mt-4"
                                onChange={handlePrnMedication}
                            />
                        </Col>
                        <Col xs="8">
                            {!checked && (
                                <Form.Group>
                                    <Form.Label>Hour</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Select
                                                name="time1"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time1options.map((time1option) => {
                                                    var isTime1Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time1option.value == formData.time1) {
                                                            isTime1Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time1option.value} value={time1option.value} selected={isTime1Selected}>
                                                            {time1option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time2"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time2options.map((time2option) => {
                                                    var isTime2Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time2option.value == formData.time2) {
                                                            isTime2Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time2option.value} value={time2option.value} selected={isTime2Selected}>
                                                            {time2option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time3"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time3options.map((time3option) => {
                                                    var isTime3Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time3option.value == formData.time3) {
                                                            isTime3Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time3option.value} value={time3option.value} selected={isTime3Selected}>
                                                            {time3option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time4"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time4options.map((time4option) => {
                                                    var isTime4Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time4option.value == formData.time4) {
                                                            isTime4Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time4option.value} value={time4option.value} selected={isTime4Selected}>
                                                            {time4option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xs="1">
                                            {locationData.orderid != null && (
                                                <Button variant="outline-secondary" size="sm" id="btnmoretime" onClick={handleMoreTime}>
                                                    +
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs="8">
                            {edittimeclicked && !checked && (
                                <Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Select
                                                name="time5"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time5options.map((time5option) => {
                                                    var isTime5Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time5option.value == formData.time5) {
                                                            isTime5Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time5option.value} value={time5option.value} selected={isTime5Selected}>
                                                            {time5option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time6"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time6options.map((time6option) => {
                                                    var isTime6Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time6option.value == formData.time6) {
                                                            isTime6Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time6option.value} value={time6option.value} selected={isTime6Selected}>
                                                            {time6option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time7"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time7options.map((time7option) => {
                                                    var isTime7Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time7option.value == formData.time7) {
                                                            isTime7Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time7option.value} value={time7option.value} selected={isTime7Selected}>
                                                            {time7option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time8"
                                                onChange={handleFieldChange}
                                                size="sm"
                                            >
                                                {time8options.map((time8option) => {
                                                    var isTime8Selected = false;
                                                    if (locationData.orderid != null) {
                                                        if (time8option.value == formData.time8) {
                                                            isTime8Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time8option.value} value={time8option.value} selected={isTime8Selected}>
                                                            {time8option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xs="1">
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Frequency</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select
                                        name="freq"
                                        onChange={handleFieldChange}
                                        size="sm"
                                    >
                                        {freqOptions.map((freqoption) => {
                                            var isFreqSelected = false;
                                            if (locationData.orderid != null) {
                                                if (freqoption.value == formData.freq) {
                                                    isFreqSelected = "selected";
                                                }
                                            }

                                            if (freqoption.value == -999) {
                                                isFreqSelected = "selected";
                                            }

                                            return (
                                                <option key={freqoption.value} value={freqoption.value} selected={isFreqSelected}>
                                                    {freqoption.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Button variant="outline-secondary" size="sm" id="btnfrequency" onClick={handlePrescriptionModal}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Route</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select
                                        name="route"
                                        onChange={handleFieldChange}
                                        size="sm"
                                    >
                                        {routeOptions.map((routeoption) => {
                                            var isRouteSelected = false;
                                            if (locationData.orderid != null) {
                                                if (routeoption.value == formData.route) {
                                                    isRouteSelected = "selected";
                                                }
                                            }

                                            if (routeoption.value == -999) {
                                                isRouteSelected = "selected";
                                            }

                                            return (
                                                <option key={routeoption.value} value={routeoption.value} selected={isRouteSelected}>
                                                    {routeoption.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Button variant="outline-secondary" size="sm" id="btnroute" onClick={handlePrescriptionModal}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Start</Form.Label>
                                <DatePicker name="startdate" selected={startdate} onChange={(startdate) => setStartDate(startdate)}
                                    className='form-control form-control-sm' placeholderText="Select Start Date" minDate={new Date()} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Stop</Form.Label>
                                <DatePicker name="stopdate" selected={stopdate} onChange={(stopdate) => setStopDate(stopdate)}
                                    className='form-control form-control-sm' placeholderText="Select Stop Date" minDate={new Date()} />
                            </Form.Group>
                        </Col>
                        {/* <Col>
                            <Form.Group>
                                <Form.Label>Expiration</Form.Label>
                                <DatePicker name="expirydate" selected={expirydate} onChange={validateExpiryDate}
                                    className='form-control' placeholderText="Select Expiry Date" minDate={new Date()} />
                            </Form.Group>
                        </Col> */}
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Reason Prescribed</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select
                                        name="rsnpres"
                                        size="sm"
                                        onChange={handleFieldChange}
                                    >
                                        {rsnOptions.map((rsnoption) => {
                                            var isRsnSelected = false;
                                            if (locationData.orderid != null) {
                                                if (rsnoption.value == formData.rsnpres) {
                                                    isRsnSelected = "selected";
                                                }
                                            }

                                            if (rsnoption.value == -999) {
                                                isRsnSelected = "selected";
                                            }

                                            return (
                                                <option key={rsnoption.value} value={rsnoption.value} selected={isRsnSelected}>
                                                    {rsnoption.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Button variant="outline-secondary" size="sm" id="btnrsnpres" onClick={handlePrescriptionModal}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Special Instruction</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select
                                        name="splins"
                                        size="sm"
                                        onChange={handleFieldChange}
                                    >
                                        {splInsOptions.map((splinsoption) => {
                                            var isSplInsSelected = false;
                                            if (locationData.orderid != null) {
                                                if (splinsoption.value == formData.splins) {
                                                    isSplInsSelected = "selected";
                                                }
                                            }

                                            if (splinsoption.value == -999) {
                                                isSplInsSelected = "selected";
                                            }

                                            return (
                                                <option key={splinsoption.value} value={splinsoption.value} selected={isSplInsSelected}>
                                                    {splinsoption.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Button variant="outline-secondary" size="sm" id="btnsplins" onClick={handlePrescriptionModal}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Call HCP If</Form.Label>
                                <Form.Control
                                    placeholder='Call HCP If'
                                    value={formData.callhcp}
                                    name="callhcp"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Pharmacy</Form.Label>
                                <Form.Select
                                    name="pharma"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {pharmacyOptions.map((pharmaoption) => {
                                        var isPharmaSelected = false;
                                        if (locationData.orderid != null) {
                                            if (pharmaoption.value == formData.pharma) {
                                                isPharmaSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={pharmaoption.value} value={pharmaoption.value} selected={isPharmaSelected}>
                                                {pharmaoption.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Med Group</Form.Label>
                                <Form.Select
                                    name="medgrp"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {medgroupOptions.map((medgrpoption) => {
                                        var isMedGroupSelected = false;
                                        if (locationData.orderid != null) {
                                            if (medgrpoption.value == formData.medgrp) {
                                                isMedGroupSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={medgrpoption.value} value={medgrpoption.value} selected={isMedGroupSelected}>
                                                {medgrpoption.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Medication Status</Form.Label>
                                <Form.Control
                                    placeholder='Medication Status'
                                    value={formData.med_status}
                                    name="med_status"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group className="position-relative">
                                <Form.Label>Early/Late</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={earlyLateTime}
                                    readOnly
                                    className="w-50"
                                    onClick={handleInputClick}
                                />
                                {showDropdown &&<Dropdown.Menu show className="shadow-sm p-2 position-absolute w-50">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeChange("hours", "up")}
                                        >
                                            ▲
                                        </Button>
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeChange("minutes", "up")}
                                        >
                                            ▲
                                        </Button>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-2">
                                        <span className="text-center w-50">{earlyLateTime.split(":")[0]}</span>
                                        <span className="text-center w-50">{earlyLateTime.split(":")[1]}</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeChange("hours", "down")}
                                        >
                                            ▼
                                        </Button>
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeChange("minutes", "down")}
                                        >
                                            ▼
                                        </Button>
                                    </div>
                                    <div className="text-end mt-2">
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={handleDropdownClose} // Close dropdown on button click
                                        >
                                            Done
                                        </Button>
                                    </div>
                                </Dropdown.Menu>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Not To Exceed</Form.Label>
                                <Form.Control
                                    className="form-control form-control-sm"
                                    placeholder="Not To Exceed"
                                    onChange={handleFieldChange}
                                    size="sm"
                                    name="exceeddate"
                                    value={formData.exceeddate}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="position-relative">
                                <Form.Label>Time between other meds</Form.Label>
                                <Form.Control
                                    type="text"                                  
                                    value={timeBetweenOtherMed}
                                    readOnly
                                    className="w-50"
                                    onClick={handleTimeBetweenOtherMedInputClick}
                                />
                                {showTimeBetweenOtherMedDropdown && <Dropdown.Menu show className="shadow-sm p-2 position-absolute w-50">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeBetweenOtherMeds("hours", "up")}
                                        >
                                            ▲
                                        </Button>
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeBetweenOtherMeds("minutes", "up")}
                                        >
                                            ▲
                                        </Button>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-2">
                                        <span className="text-center w-50">{timeBetweenOtherMed.split(":")[0]}</span>
                                        <span className="text-center w-50">{timeBetweenOtherMed.split(":")[1]}</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeBetweenOtherMeds("hours", "down")}
                                        >
                                            ▼
                                        </Button>
                                        <Button
                                            variant="link"
                                            className="p-0 text-dark"
                                            onClick={() => handleTimeBetweenOtherMeds("minutes", "down")}
                                        >
                                            ▼
                                        </Button>
                                    </div>
                                    <div className="text-end mt-2">
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={handleTimeBetweenOtherMedDropdownClose} // Close dropdown on button click
                                        >
                                            Done
                                        </Button>
                                    </div>
                                </Dropdown.Menu>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_medsheet"
                                id='is_medsheet'
                                checked={
                                    formData.is_medsheet
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_medsheet: e.target.checked
                                    })
                                }
                                label='Medsheet'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_treatment"
                                id='is_treatment'
                                checked={
                                    formData.is_treatment
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_treatment: e.target.checked
                                    })
                                }
                                label='Treatment'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_physpres"
                                id='is_physpres'
                                checked={
                                    formData.is_physpres
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_physpres: e.target.checked
                                    })
                                }
                                label='Physician Order'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_vital"
                                id='is_vital'
                                checked={
                                    formData.is_vital
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_vital: e.target.checked
                                    })
                                }
                                label='Vital Signs'
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Label>Attach Document</Form.Label>
                            <Form.Control type="file" size="sm" />
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="w-100 mt-4">
                                    {locationData.orderid != null ? "Update" : "Save"}
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                </Card.Body>
            </Card>
            <PrescriptionModal show={showModal} setShow={setShowModal} handlePrescriptionModalValue={handlePrescriptionModalValue} />
        </Form >
    );
};

AddEditPrescription.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

AddEditPrescription.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default AddEditPrescription;
