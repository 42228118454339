import React, { useState, useEffect, useMemo } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import IconItem from 'components/common/icon/IconItem';
import AddNotesModal from './AddNotesModal';
import DrugsModal from './DrugsModal';
import { toast } from 'react-toastify';

const CensusGrid = (props) => {

    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [drugsData, setDrugsData] = useState([]);
    const [medChecked, setMedCheck] = useState(false);
    const [tableGridData, setTableGridData] = useState([]);

    const columns = React.useMemo(() =>
        [
            {
                accessorKey: 'patient',
                header: 'Patient',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'sign_off',
                header: 'D/C Label Change or Split Packs',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                },
                cell: ({ row: { original } }) => {
                    return <>
                        <Row>
                            <Col xs="2">
                                <Form.Check
                                    type='radio'
                                    name={"pack" + original.index}
                                    id={"is_yes" + original.index}
                                    label='Yes'
                                    onChange={(e) => { handleEditDrugsData(e, original.refilldrugsdata, original.patient, original.index, original.patientid) }}
                                    onClick={(e) => { handleEditDrugsData(e, original.refilldrugsdata, original.patient, original.index, original.patientid) }}
                                    
                                    defaultChecked={original.sign_off === 2}
                                />
                            </Col>
                            <Col xs="2">
                                <Form.Check
                                    type='radio'
                                    name={"pack" + original.index}
                                    id={"is_no" + original.index}
                                    label='No'
                                    defaultChecked={original.sign_off === 1}
                                    // defaultChecked={medChecked}
                                    onChange={(e) => { handleRowData(1, "sign_off", original.index) }}
                                />
                            </Col>
                        </Row>
                    </>
                }
            },
            {
                accessorKey: 'status',
                header: 'Status',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                },
                cell: ({ row: { original } }) => {
                    // console.log("census status original" , original);
                    var isActiveSelected = original.census_status == 1 ? 'selected' : false;
                    var isDischargedSelected = original.census_status == 2 ? 'selected' : false;
                    var isDeceasedSelected = original.census_status == 3 ? 'selected' : false;
                    var isMloaSelected = original.census_status == 4 ? 'selected' : false;
                    
                    return <>
                        <Form.Group>
                            <Form.Select
                                name={"census_status" + original.index}
                                onChange={(e) => { handleRowData(e.target.value, "census_status", original.index) }}
                                size="sm">
                                <option key="pending" value="0">
                                    Pending
                                </option>
                                <option key="active" value="1" selected={isActiveSelected}>
                                    Active
                                </option>
                                <option key="discharged" value="2" selected={isDischargedSelected} >
                                    Discharged
                                </option>
                                <option key="deceased" value="3" selected={isDeceasedSelected}>
                                    Deceased
                                </option>
                                <option key="mloa" value="4" selected={isMloaSelected}>
                                    Mloa
                                </option>
                            </Form.Select>
                        </Form.Group >
                    </>
                }
            },
            {
                accessorKey: 'facility_notes',
                header: 'Facility Note',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'pharmacy_notes',
                header: 'Pharmacy Note',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'action',
                header: 'Add Notes',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                },
                cell: ({ row: { original } }) => {
                    return <IconItem
                        tag="button"
                        icon={['far', 'edit']}
                        size="sm"
                        onClick={() => handleAddNotes(original.patient, original.patientid)}
                        className="btn rounded-3 me-2 fs-11 align-items-center"
                    />
                }
            }

        ], [medChecked]
    );

    const [showModal, setShowModal] = useState(false);
    const [showDrugsModal, setShowDrugsModal] = useState(false);
    const [refill, setRefill] = useState([]);

    const handleAddNotes = (pname, pid) => {
        setPatientName(pname);
        setPatientId(pid);
        setShowModal(true);
    };

    function handleEditDrugsData(e, drugsdata, pname, index, pid) {
        if (e.target.checked) {
            handleRowData(0, "sign_off", index);
            setPatientId(pid);
            setPatientName(pname);
            setDrugsData(drugsdata);
            setShowDrugsModal(true);
        }
    }

    const handleRadioChecked = (e) => {
        if (e.target.checked) {
            setMedCheck(true);
        } else {
            setMedCheck(false);
        }
    }

    const handleSkipMedsChecked = (e) => {

        if (e.target.checked) {
            setMedCheck(true);
        } else {
            setMedCheck(false);
        }

    }

    useEffect(() => {

        fetchPatientData();
        //console.log("props.patientdetails_arr" + JSON.stringify(props.patientdetails_arr));

    }, [props.patientdetails_obj]);

    async function fetchPatientData() {

        let results = [];
        let ctr = 0;

        for (let patient in props.patientdetails_obj) {

            let patientlistObj = props.patientdetails_obj[patient];
            let refillObj = [];
            let cyclepatientid = "";

            refillObj.push(patientlistObj["prescriptions"].will_be_filled)


            refillObj.forEach(function (arrayItem) {
                for (let singleitem in arrayItem) {
                    cyclepatientid = arrayItem[singleitem].cycle_patient_id;
                }
            });

            // console.log("patientlistObj" , patientlistObj);
            // console.log("refillObj" , refillObj);

            results.push({
                index: ctr++,
                patient: patientlistObj["patient_first"] + " " + patientlistObj["patient_last"],
                refilldrugsdata: refillObj,
                patientid: patientlistObj.patient_id,
                sign_off: patientlistObj["sign_off"],
                census_status: patientlistObj["census_status"],
                facility_notes: patientlistObj["facility_notes"],
                pharmacy_notes: patientlistObj["pharmacy_notes"]
            })

        }

        //console.log("results " + results);
        setRefill(results);
        //setTableGridData(results);
        localStorage.setItem("censusgridresults", JSON.stringify(results));

        //console.log("tableGridData" + tableGridData);
    }

    const handleRowData = (gridControlValue, gridControlHeader, gridControlIndex) => {
        let tempArrData = JSON.parse(localStorage.getItem("censusgridresults"));//tableGridData;
        //console.log("tableOpData ip" + JSON.stringify(tempArrData));

        tempArrData.forEach(function (arrayItem, index) {
            if (index == gridControlIndex) {
                //console.log("index" + JSON.stringify(arrayItem) + gridControlValue);
                arrayItem[gridControlHeader] = gridControlValue;

            }
        });

        //setTableGridData(tempArrData);
        localStorage.setItem("censusgridresults", JSON.stringify(tempArrData));

        //console.log("tableOpData Op" + JSON.stringify(tempArrData));

    }

    const handleSave = async (e) => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let censusGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-patients`;

        //console.log("tableOpData" + JSON.stringify(tableGridData));

        let censusGridData = []

        let censusTableGridData = JSON.parse(localStorage.getItem("censusgridresults"));

        censusTableGridData.forEach(function (arrayItem, index) {
            let singlerowitem =
            {
                "cycle_patient_id": arrayItem["patientid"],
                "sign_off": arrayItem["sign_off"],
                "census_status": arrayItem["census_status"],
            }
            censusGridData.push(singlerowitem);
        });

        // console.log("notesData " + JSON.stringify(censusGridData));

        const { data } = await axios.post(censusGridUrl, censusGridData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("Census Details added successfully");
        }
        else {
            toast.error("Error occured while filling Census Details ");
        }

    }

    const table = useAdvanceTable({
        data: refill,
        columns,
        selection: false,
        sortable: false,
        pagination: true,
        perPage: 10
    });

    return (
        <>
            <AdvanceTableProvider {...table}>
                <Row className='mt-3 justify-content-between'>
                    <Col xs="4" className="mb-3">
                        <AdvanceTableSearchBox placeholder="Search..." />
                    </Col>
                    <Col xs="6">
                        <Form.Check
                            type='checkbox'
                            name="skipcycle"
                            id='is_skipcycle'
                            label='Skip Cycle med changes for all clients?'
                            onChange={handleSkipMedsChecked}
                        />
                    </Col>
                    <Col xs="2">
                        <Button color="primary" className="w-100" onClick={handleSave}>Save</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="mb-1 mt-1">
                        <AdvanceTable
                            headerClassName="bg-200 text-nowrap align-middle text-center"
                            rowClassName="text-nowrap align-middle text-center"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs-10 mb-0 overflow-hidden'
                            }}
                        />
                    </Col>
                </Row>
                <Card.Footer>
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </Card.Footer>
            </AdvanceTableProvider>
            <AddNotesModal show={showModal} setShow={setShowModal} patientname={patientName} patientid={patientId} notesby="Facility" />
            <DrugsModal show={showDrugsModal} setShow={setShowDrugsModal} patientname={patientName} patientid={patientId} drugsdata={drugsData} />
        </>
    );
};

export default CensusGrid;
