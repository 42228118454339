import React from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import { toast } from 'react-toastify';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Spinner from 'react-bootstrap/Spinner';

const columns = [
    {
        accessorKey: 'month',
        header: 'Report Date',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'download',
        header: 'Report Download',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    }
];


const CurrentMed = (props) => {

    const [currentmedrpt, setCurrentMed] = useState("");
    const [spinner, showSpinner] = useState(false);
    const [formData, setFormData] = useState({
        reportformat: '',
        is_print: false,
    });

    const [reportdate, setReportDate] = useState(null);
    const [showResults, setShowResults] = React.useState(false)

    const navigate = useNavigate();

    // Handler
    const handleSubmit = async e => {

        e.preventDefault();

        if ((localStorage.getItem("patientId") != null || localStorage.getItem("reportFacilityId") != null) && formData.reportformat != "") {

            showSpinner(true);

            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsCurrentMedUrl = `${process.env.REACT_APP_SERVER_URL}` + "/reports/currentmeds";

            let pelmedsCurrentMedData = localStorage.getItem("patientId") != null ?
                {
                    patient_id: localStorage.getItem("patientId"),
                    currentmed_format: formData.reportformat,
                    is_print: formData.is_print
                } :
                {
                    facility_id: localStorage.getItem("reportFacilityId"),
                    currentmed_format: formData.reportformat,
                    is_print: formData.is_print
                };

            //console.log("pelmedsCurrentMedData " + JSON.stringify(pelmedsCurrentMedData));

            const { data } = await axios.post(pelmedsCurrentMedUrl, pelmedsCurrentMedData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            if (data.status) {

                //setCurrentMed(data.medsheet_url);
                //setShowResults(true);

                //console.log(JSON.stringify(currentmedrpt));            
                toast.success("CurrentMed Report Generated Successfully");
                showSpinner(false);
                if (localStorage.getItem("reportFacilityId") == null)
                    props.onCallParent(); //only required for reports at patient level not at facility level
            }
            else {
                toast.error("Error while generating the currentmed report");
            }

            showSpinner(false);
        }
        else {
            toast.error("Required Fields are empty");
        }

    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label className="required">Report Format</Form.Label>
                            <Form.Select name="reportformat"
                                onChange={handleFieldChange}
                                size="sm">
                                <option value=''>Select your Format</option>
                                <option value='1'>Current Medication Template</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_print"
                                        id='is_print'
                                        checked={
                                            formData.is_print
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_print: e.target.checked
                                            })
                                        }
                                        label='Print Medical Record'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <Form.Group>
                            {spinner && (
                                <Button variant="primary" disabled className="mt-1 w-100">
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="p-2" style={{ "verticalAlign": "3px" }}>Fetching...</span>
                                </Button>
                            )}
                            {!spinner && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mt-1 w-100">
                                    Generate
                                </Button>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                    </Col>
                </Row>
            </Form>
            <br />
            {showResults && (
                <a href={currentmedrpt} target="_blank" rel="noreferrer">Download Report</a>

            )}
        </div>
    );
};

CurrentMed.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

CurrentMed.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default CurrentMed;
