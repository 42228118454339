import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";

const AddNotesModal = ({ show, setShow, patientname, patientid, notesby }) => {

    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [notesBy, setNotesBy] = useState("");
    const [pharmacyNotes, setPharmacyNotes] = useState("");
    const [facilityNotes, setFacilityNotes] = useState("");

    useEffect(() => {

        console.log("patientname " + patientname + patientid);

        setPatientName(patientname);
        setPatientId(patientid);
        setNotesBy(notesby);
        setPharmacyNotes("No Notes")
        setFacilityNotes("No Notes")


    }, [patientname, notesby]);

    const handleSave = async (e) => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let notesUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-patients`;

        let notesData = notesBy == "Facility" ?
            [{
                "cycle_patient_id": patientId,
                "facility_notes": facilityNotes
            }] :
            [{
                "cycle_patient_id": patientId,
                "pharmacy_notes": pharmacyNotes
            }];

        console.log("notesData " + JSON.stringify(notesData));

        const { data } = await axios.post(notesUrl, notesData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success(notesBy + " Notes added successfully");
            setShow(false);
        }
        else {
            toast.error("Error occured while adding Notes ");
        }

    }


    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Notes for {patientName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form >
                    <Row>
                        {notesBy == "Facility" && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label className="required">Facility Notes</Form.Label>
                                    <Form.Control as="textarea" placeholder="Enter Facility Notes" name="facilityarea" onChange={(e) => { setFacilityNotes(e.target.value) }} aria-label="With textarea" rows="3" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="required">Pharmacy Notes</Form.Label>
                                    <h6>{pharmacyNotes}</h6>
                                </Form.Group>
                            </>
                        )}
                    </Row>
                    <Row>
                        {notesBy == "Pharmacy" && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label className="required">Pharmacy Notes</Form.Label>
                                    <Form.Control as="textarea" placeholder="Enter Pharmacy Notes" name="pharmacyarea" onChange={(e) => { setPharmacyNotes(e.target.value) }} aria-label="With textarea" rows="3" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="required">Facility Notes</Form.Label>
                                    <h6>{facilityNotes}</h6>
                                </Form.Group>
                            </>
                        )}
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    name="btnpatientmodalsubmit"
                                    className="mt-4 w-50"
                                    onClick={handleSave}>
                                    Save
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

AddNotesModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default AddNotesModal;


