import React, { useState } from 'react';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { toast } from 'react-toastify';

const HoldReasonTable = (props) => {

    function handleEditInput(editValue) {
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'holdreason',
            header: 'Hold Reason',
            meta: {
                headerProps: { className: 'pe-1 col-lg-8 text-start text-900' }
            }
        },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.holdreason + "," + original.is_active + "," + original.holdreason_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [holdreason, setHoldReason] = useState([]);


    useEffect(() => {

        async function fetchHoldReasonDetails() {

            let tokenStr = localStorage.getItem("accessToken");

            const reasonsData = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/drugs/list-hold-reasons/", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const presReasonsData = reasonsData.data.hold_reasons['data'];

            if (presReasonsData.length > 0) {
                presReasonsData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            holdreason: value.reason,
                            holdreason_id: value.id,
                            is_active: value.is_active
                        });
                    }
                });

                setHoldReason(results);
            }
            else {
                toast.info("No Reasons found in this list");
            }
        }

        fetchHoldReasonDetails();

        async function refreshHoldReasonDetails() {
            fetchHoldReasonDetails();
        }

        if (props.trigger > 0) {
            refreshHoldReasonDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: holdreason,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle "//text-center
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
        </div>
    );
};

export default HoldReasonTable;
