import React from 'react';

import { Card, Col, Form, Row, Button, InputGroup } from 'react-bootstrap';
import Select from "react-select";
import PropTypes from 'prop-types';
import paths from 'routes/paths';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import FalconCardHeader from 'components/common/FalconCardHeader';
import DatePicker from "react-datepicker";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Flex from 'components/common/Flex';
import moment from "moment";
import { toast } from 'react-toastify';
import CycleStatusGrid from './CycleStatusGrid';
import * as mapYourMedsConstant from '../../common/mapYourMedsConstant';

const CycleStatus = ({ bodyClassName }) => {

    const [startdate, setStartDate] = useState(null);
    const [enddate, setEndDate] = useState(null);
    const [options, setOptions] = useState([{ key: 'Select a program', value: '' }]);
    const [grpoptions, setGrpOptions] = useState([]);
    const [facilityobj, setFacilityObj] = useState([]);
    const [censustypeoptions, setCensusTypeOptions] = useState([]);
    const [censusstatusdata, setCensusStatusData] = useState({});
    const [groupdata, setGroupData] = useState();
    const location = useLocation();
    const data = location.state;

    let initialFormData = {
        group: '',
        facility: '',
        censustype: '',
        startdate: '',
        enddate: ''
    };

    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        populateData();
    }, []);

    async function populateData() {

        //let tokenStr = localStorage.getItem("accessToken");

        //set group details

        //set groupid
        // let groupResults = JSON.parse(localStorage.getItem("groupData"));
        // setGrpOptions([
        //     { key: 'Select a group', value: '' },
        //     ...groupResults
        // ]);

        //Todo Need to fix this based on role and group
        //console.log("facilitiesData " + localStorage.getItem("facilitiesData"));
        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        setOptions([
            { key: 'Select a program', value: '' },
            ...results
        ]);
        // const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/get-all-facility`, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        // setGroupData(data.data);

        //set census types
        let censusresults = [
            // {
            //     key: "All",
            //     value: mapYourMedsConstant.CENSUS_STATUS_ALL
            // },
            {
                key: "Completed",
                value: mapYourMedsConstant.CENSUS_STATUS_COMPLETED
            },
            {
                key: "Pending",
                value: mapYourMedsConstant.CENSUS_STATUS_PENDING
            }

        ]

        setCensusTypeOptions([
            { key: 'Select a census', value: '' },
            ...censusresults
        ]);

    }

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            facility: e.value
        });

        

        console.log("formData" , formData);


        // setFormData({
        //     ...formData,
        //     [e.target.name]: e.target.value
        // });

    };

    const handleGroupFieldChange = e => {

        let groupdetails = groupdata[e.target.value];

        let facilityresults = [];

        groupdetails["facilities"].map((option) => {
            facilityresults.push({
                key: option.facility_name,
                value: option.id
            });
        });

        setOptions([
            { key: 'Select a program', value: '' },
            ...facilityresults
        ]);

        setFacilityObj(facilityresults);

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        let cycleStatusData = {
            //group: formData.group,
            facility: formData.facility,
            censustype: formData.censustype,
            facility_obj: facilityobj,
            startdate: moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            enddate: moment(enddate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        };

        setCensusStatusData(cycleStatusData);

        console.log("cycleStatusFORM " + JSON.stringify(cycleStatusData));
    }

    return (
        <>
            <Form onSubmit={handleSubmit} name="cyclestatusform">
                <Card>
                    <FalconCardHeader
                        title="Cycle Status"
                        titleTag="h6"
                        className="py-2"
                        light
                    />
                    <Card.Body
                        className={classNames(bodyClassName, 'h-100')}
                        as={Flex}
                        direction="column"
                        alignItems="between"
                    >
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Facility</Form.Label>
                                    {/* <Form.Select
                                        name="facility"
                                        size="sm"
                                        onChange={handleFieldChange}
                                    >
                                        {options.map((option) => {
                                            return (
                                                <option key={option.value} value={option.value}>
                                                    {option.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select> */}
                                    <Select
                                        name="facility"
                                        closeMenuOnSelect={true}
                                        options={options}
                                        placeholder='Select...'                                    
                                        classNamePrefix="react-select"
                                        getOptionLabel={option => option.key}
                                        getOptionValue={option => option.value}
                                        // value={value}
                                        onChange={(e) => handleFieldChange(e)}
                                    />
                                        
                                    

                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Census Type</Form.Label>
                                    <Form.Select
                                        name="censustype"
                                        size="sm"
                                        onChange={handleFieldChange}
                                    >
                                        {censustypeoptions.map((option) => {
                                            return (
                                                <option key={option.value} value={option.value}>
                                                    {option.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">Start Date</Form.Label>
                                    <DatePicker name="startdate" selected={startdate} onChange={(startdate) => setStartDate(startdate)}
                                        className='form-control form-control-sm' placeholderText="Select Start Date" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">End Date</Form.Label>
                                    <DatePicker name="enddate" selected={enddate} onChange={(enddate) => setEndDate(enddate)}
                                        className='form-control form-control-sm' placeholderText="Select End Date" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col>
                                <Form.Group>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-100 mt-3"
                                        disabled={!enddate || !startdate}>
                                        Search
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
            <br />
            <Card>
                <Card.Body>
                    <CycleStatusGrid cyclestatusdata={censusstatusdata} />
                </Card.Body>
            </Card>
        </>
    );
};

export default CycleStatus;