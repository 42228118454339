import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import { toast } from 'react-toastify';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Spinner from 'react-bootstrap/Spinner';

const columns = [
    {
        accessorKey: 'month',
        header: 'Report Date',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'download',
        header: 'Report Download',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    }
];

//const [medsheet, setMedSheet] = useState([]);


const HcpOrder = (props) => {

    const [hcporderrpt, setHcpOrder] = useState("");
    const [hcpoptions, setHcpOptions] = useState([]);
    const [spinner, showSpinner] = useState(false);

    const [formData, setFormData] = useState({
        hcp: '',
        reportformat: ''
    });

    const [reportdate, setReportDate] = useState(null);
    const [showResults, setShowResults] = React.useState(false)
    // const [hcpoptions, setHcpOptions] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchHcpData();
    }, []);

    async function fetchHcpData() {

        let tokenStr = localStorage.getItem("accessToken");
        //TODO confirm with SHANKS whether listing is based on facility id or not
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/doctors/list-doctors", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let results = []

        //console.log("Doctor Data Status " + data.status);
        let objPatientData = data.data;

        objPatientData.forEach((value) => {
            results.push({
                key: value.firstname + ", " + value.lastname,
                value: value.id
            });
        });

        setHcpOptions([
            ...results
        ])

    }

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();

        if ((localStorage.getItem("patientId") != null || localStorage.getItem("reportFacilityId") != null) && formData.reportformat != "" && formData.hcp != "") {

            showSpinner(true);

            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsHcpOrderUrl = `${process.env.REACT_APP_SERVER_URL}` + "/reports/hcporder";

            let pelmedsHcpOrderData = localStorage.getItem("patientId") != null ?
                {
                    hcp: formData.hcp,
                    patient_id: localStorage.getItem("patientId"),
                    medsheet_format: formData.reportformat
                } :
                {
                    hcp: formData.hcp,
                    facility_id: localStorage.getItem("reportFacilityId"),
                    medsheet_format: formData.reportformat
                };

            //console.log("pelmedsHcpOrderData " + JSON.stringify(pelmedsHcpOrderData));

            const { data } = await axios.post(pelmedsHcpOrderUrl, pelmedsHcpOrderData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            if (data.status) {

                // setHcpOrder(data.medsheet_url);
                // setShowResults(true);
                //console.log(JSON.stringify(hcporderrpt));

                toast.success("HCP Order Report Generated Successfully");
                if (localStorage.getItem("reportFacilityId") == null)
                    props.onCallParent(); //only required for reports at patient level not at facility level
            }
            else {
                toast.error("Error while generating the HCP Order report");
            }
            showSpinner(false);
        }
        else {
            toast.error("Required Fields are empty");
        }

    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label className="required">HCP</Form.Label>
                            <Form.Select
                                name="hcp"
                                onChange={handleFieldChange}
                                size="sm">
                                <option key="Select Individual" value="">Choose HCP</option>
                                {hcpoptions.map((option) => {
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label className="required">Report Format</Form.Label>
                            <Form.Select name="reportformat"
                                onChange={handleFieldChange} size="sm">
                                <option value=''>Select your Format</option>
                                <option value='1'>HCP Order Default Template</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <Form.Group>
                            {spinner && (
                                <Button variant="primary" disabled className="mt-3 w-100">
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="p-2" style={{ "verticalAlign": "3px" }}>Fetching...</span>
                                </Button>
                            )}
                            {!spinner && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mt-3 w-100">
                                    Generate
                                </Button>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                    </Col>
                </Row>
            </Form>
            <br />
            {showResults && (
                <a href={hcporderrpt} target="_blank" rel="noreferrer">Download Report</a>

            )}
        </div>
    );
};

HcpOrder.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

HcpOrder.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default HcpOrder;
