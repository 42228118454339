import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import avatar from 'assets/img/team/avatar.png';
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const ViewFacilityStaffProfileHeader = (props) => {
const data = props.data
    return (
        <Card>
            <Card.Header className="pb-0 pt-4">
                <h4 className="mb-1">
                    {data?.first_name}
                </h4>
            </Card.Header>
            <Card.Body className="py-0">
                <Row>
                    <Col lg={4}>
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                {/* <h6 className="mb-0">Allergies:</h6> */}
                            </div>
                        </Flex>
                    </Col>
                    <Col lg={4} className="ps-2 ps-lg-3 me-0 ms-auto">
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Facility Name:  </h6>
                            </div>
                        </Flex>
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Group Name: </h6>
                            </div>
                        </Flex>
                    </Col>

                    <Row className="col-12 mx-0 w-100">
                        <Avatar
                            size="auto"
                            className="avatar-profile col-1 mx-0 px-0"
                            src={avatar}
                            mediaClass="img-thumbnail shadow-sm"
                        />
                    </Row>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ViewFacilityStaffProfileHeader