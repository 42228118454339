import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';


const columns = [
    {
        accessorKey: 'patient',
        header: 'Patient',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'dob',
        header: 'Dob',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'doctor',
        header: 'Doctor',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    // {
    //     accessorKey: 'doctorphone',
    //     header: 'Doctor Phone',
    //     meta: {
    //         headerProps: { className: 'text-900 text-start w-10' },
    //         cellProps: { className: 'text-start' }
    //     }
    // },
    {
        accessorKey: 'rx',
        header: 'Rx',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'drug',
        header: 'Drug',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'refill',
        header: 'Refill',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'expiredate',
        header: 'Expire Date',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'presstatus',
        header: 'Prescription Status',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'note',
        header: 'Note',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'dated',
        header: 'Dated',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'user',
        header: 'User',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'addnote',
        header: 'Add Note',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    }

];

const CycleNeedRefillAdminGrid = (props) => {

    const [refill, setRefill] = useState([]);

    useEffect(() => {

        fetchPatientData();

    }, [props.patientdetails_arr]);

    async function fetchPatientData() {

        let results = [];
        // results.push({
        //     patient: 'DEMO 15, PATIENT',
        //     dob: '09/09/91',
        //     doctor: 'COMMUNITY, PELHAM',
        //     doctorphone: '7819662700',
        //     rx: '4367821',
        //     drug: 'METOPROLOL SUCCINATE',
        //     refill: '11',
        //     expiredate: '01/24/24',
        //     presstatus: 'Prescription has Expired'
        // });
        // setRefill(results);

        for (let patient in props.patientdetails_arr) {

            let patientlistObj = props.patientdetails_arr[patient];
            let refillObj;

            if (props.status == "needrefill")
                refillObj = patientlistObj["prescriptions"].need_refill;
            else
                refillObj = patientlistObj["prescriptions"].will_be_filled;

            refillObj.forEach((value) => {
                results.push({
                    patient: patientlistObj["patient_first"] + " " + patientlistObj["patient_last"],
                    dob: patientlistObj["patient_dob"],
                    doctor: patientlistObj["doctor_first"] + " " + patientlistObj["doctor_last"],
                    doctorphone: patientlistObj["doctor_phone"],
                    rx: value.pharmacy_order_id,
                    drug: value.drug,
                    refill: value.refill_remaining,
                    expiredate: value.pharmacy_expire_date,
                    presstatus: value.rx_status,
                    note: value.change_note,
                    dated: moment(value.pharmacy_expire_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                    user: '',
                    addnote: ''
                })
            });

        }

        setRefill(results);
    }

    const table = useAdvanceTable({
        data: refill,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10
    });

    return (
        <AdvanceTableProvider {...table}>
            <Row className='mt-3 justify-content-between'>
                <Col xs="4" className="mb-3">
                    <AdvanceTableSearchBox placeholder="Search..." />
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mb-1 mt-1">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle text-center"
                        rowClassName="text-nowrap align-middle text-center"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Col>
            </Row>
            <Card.Footer>
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </Card.Footer>
        </AdvanceTableProvider>
    );
};

export default CycleNeedRefillAdminGrid;