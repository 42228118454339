import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';


const EditCurrent = (props) => {
    const [formData, setFormData] = useState([]);
    const [medSheet, setMedSheet] = useState([]);
    const [disabledFlag, setDisabledFlag] = useState(true);
    const { id } = props

    let medSheettype = [
        { key: '1', value: '1' },
        { key: '2', value: '2' },
        { key: '3', value: '3' },
        { key: '4', value: '4' },
        { key: '5', value: '5' },
        { key: '6', value: '6' },
        { key: '7', value: '7' }
    ]

    useEffect(() => {
        fetchData(id)
    }, [])

    async function fetchData(id) {
        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/group-profile/` + id, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let objData = data?.data[0];

        setMedSheet([
            { key: 'Select MedSheet Type', value: '' },
            ...medSheettype
        ])
        setFormData(objData);
        setDisabledFlag(false);
    }

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault()
        let groupData = {
            group_id: id,
            medsheet_format: formData.medsheet_format,
            is_peltask_active: formData.is_peltask_active,
            is_mym_active: formData.is_mym_active,
            is_mobile_app_active: formData.is_mobile_app_active,
            enable_device_approve: formData.enable_device_approve
        }
        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/facilities/update-group", groupData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("Group Details Updated successfully");
        }
        else {
            toast.info("Error");
        }
    }
    return (
        <>
            <Card className="mb-3">
                <Card.Body >
                    <Form onSubmit={handleSubmit} name="CurrentEditForm">
                        <Row className="mb-2">
                            <Col>
                                <Form.Check
                                    type='switch'
                                    name="is_peltask_active"
                                    id='is_peltask_active'
                                    checked={
                                        formData.is_peltask_active
                                    }
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            is_peltask_active: e.target.checked ? 1 : 0
                                        })
                                    }
                                    label='Peltask'
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    name="is_mym_active"
                                    id='is_mym_active'
                                    checked={
                                        formData.is_mym_active
                                    }
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            is_mym_active: e.target.checked ? 1 : 0
                                        })
                                    }
                                    label='MapYourMeds'
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Check
                                    type='switch'
                                    name="is_mobile_app_active"
                                    id='is_mobile_app_active'
                                    checked={
                                        formData.is_mobile_app_active
                                    }
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            is_mobile_app_active: e.target.checked ? 1 : 0
                                        })
                                    }
                                    label='Pelpak'
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    name="enable_device_approve"
                                    id='enable_device_approve'
                                    checked={
                                        formData.is_mobile_app_active ? formData.enable_device_approve : 0
                                    }
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            enable_device_approve: e.target.checked ? 1 : 0
                                        })
                                    }
                                    label='Device Approve'
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Print Medsheet</Form.Label>
                                    <Form.Select
                                        name="medsheet_format"
                                        onChange={handleFieldChange}
                                        size="sm"
                                    >
                                        {medSheet.map((option) => {
                                            let isMedSheetSelected = false;
                                            if (option.value == formData.medsheet_format) {
                                                isMedSheetSelected = "selected";
                                            }
                                            return (
                                                <option key={option.value} value={option.value} selected={isMedSheetSelected} >
                                                    {option.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Group>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-40 mt-4"
                                        disabled={disabledFlag}>
                                        {"Save"}
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

export default EditCurrent