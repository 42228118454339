import React, { useEffect } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import axios from "axios";
import Flex from 'components/common/Flex';
import moment from "moment";
import CycleChecklist from './CycleChecklist';
import CycleTabDetails from './CycleTabDetails';
import classNames from 'classnames';
import DeliveryModal from './DeliveryModal';
import { toast } from 'react-toastify';

const CycleDetails = () => {
    let { paramsFacilityId } = useParams();
    const location = useLocation();
    const locationData = location.state;
    const [options, setOptions] = useState([{ key: 'Select Facility', value: '' }]);
    // const [grpoptions, setGrpOptions] = useState([]);
    const [cycledateoptions, setCycleDateOptions] = useState([{ key: 'Select CycleDate', value: '' }]);
    // const [cyclestartdateoptions, setCycleStartDateOptions] = useState([{ key: 'Select Cycle to get Cycle Start Date', value: '' }]);
    const [cycledateflag, setCycleDateFlag] = useState(false);
    const [facilitydata, setFacilityData] = useState();
    const [cycleinfoitems, setCycleInfoItems] = useState([]);
    const [cyclesummaryitems, setCycleSummaryItems] = useState([]);
    const [patientdetails, SetPatientDetails] = useState();
    const [initialFacilityId, setInitialFacilityId] = useState();
    const [initialCycleDate, setInitialCycleDate] = useState();
    const [initialGroupId, setInitialGroupId] = useState();
    const [censusGrid, showCensusGrid] = useState(false);
    const [groupdata, setGroupData] = useState();
    const [facilityobj, setFacilityObj] = useState([]);
    const [censuscompleted, setCensusCompleted] = useState(false);
    const [censuscompleteddet, setCensusCompletedDet] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [localdeliverydate, setLocalDeliveryDate] = useState([]);
    const [deliverydate, setDeliveryDate] = useState([]);
    const [cycleId, setCycleId] = useState("");


    useEffect(() => {
        populateData();
    }, []);

    async function populateData() {

        let tokenStr = localStorage.getItem("accessToken");
        //console.log("facilitiesData " + localStorage.getItem("facilitiesData"));

        //set group details
        // if (localStorage.getItem("roleId") < 4) {

        //     //set groupid
        //     let groupResults = JSON.parse(localStorage.getItem("groupData"));

        //     const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/get-all-facility`, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        //     setGroupData(data.data);

        //     setGrpOptions([
        //         { key: 'Select a group', value: '' },
        //         ...groupResults
        //     ]);

        //     // setInitialGroupId(groupResults[0].value);
        // }
        // else {
        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        setOptions([
            { key: 'Select a program', value: '' },
            ...results
        ]);

        //console.log("FacilityResults " + JSON.stringify(results));
        console.log("paramsFacilityId " + paramsFacilityId);

        if (paramsFacilityId !== undefined) {
            setInitialFacilityId(paramsFacilityId);
            setInitialCycleStartDate(paramsFacilityId);
        }
        else {
            setInitialFacilityId(results[0].value);
            setInitialCycleStartDate(results[0].value);
        }
        // }

        //let initialcyclestartdate = setInitialCycleStartDate(results[0].value);
        //console.log("i m getting called" + JSON.stringify(initialcyclestartdate));
        //setCycleDate(initialcyclestartdate);

    }

    const handleGroupFieldChange = e => {

        let groupdetails = groupdata[e.target.value];

        let facilityresults = [];

        groupdetails["facilities"].map((option) => {
            facilityresults.push({
                key: option.facility_name,
                value: option.id
            });
        });

        setOptions([
            { key: 'Select a program', value: '' },
            ...facilityresults
        ]);

        setFacilityObj(facilityresults);

        // setFormData({
        //     ...formData,
        //     [e.target.name]: e.target.value
        // });
    };

    const handleFacilityFieldChange = e => {
        //set cycle dates        
        setInitialCycleStartDate(e.target.value);
    };

    const handleCycleDate = async e => {
        setCycleDate(e.target.value, facilitydata)
    };

    const setInitialCycleStartDate = async (facilityId) => {
        let tokenStr = localStorage.getItem("accessToken");

        console.log("facilityId " + facilityId);

        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/cycles/list/` + facilityId, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        let cycleData = data.cycleData;

        //console.log("cycleData" + JSON.stringify(cycleData));

        setFacilityData(cycleData);

        let cycleResults = [];

        for (let cyclekey in cycleData) {
            //console.log("cycleri " + cyclekey);
            cycleResults.push({
                key: moment(cyclekey, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                value: cyclekey
            })
        }

        setCycleDateOptions([
            { key: 'Select Cycledate', value: '' },
            ...cycleResults
        ]);

        setInitialCycleDate(cycleResults[0].value);
        setCycleDate(cycleResults[0].value, cycleData);

    }

    const setCycleDate = (cycleDate, facilitydata) => {

        //console.log("facilitydata " + JSON.stringify(facilitydata));

        let singleCycleData = facilitydata[cycleDate]
        let cycleInfoItems = [];
        let cycleSummaryItems = [];
        let deliverydateItems = [];
        let patientlist;

        //console.log(JSON.stringify(singleCycleData));

        singleCycleData.forEach((item) => {

            let deliverydate = item.delivery_date != null ? moment(item.delivery_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : "";
            let deliverytime = item.delivery_time != null ? item.delivery_time : "";
            let updatedby = item.delivery_date_facility_updated_by != null ? item.delivery_date_facility_updated_by : "";
            let updatedon = item.delivery_date_facility_updated_on != null ? item.delivery_date_facility_updated_on : "";

            cycleInfoItems.push(
                {
                    key: "Delivery Date and Time:",
                    value: deliverydate.concat("  " + deliverytime)
                },
                {
                    key: "Reason:",
                    value: item.delivery_date_pharmacy_update_reason != null ? item.delivery_date_pharmacy_update_reason : "-"
                },
                {
                    key: "Changed:",
                    value: "-"
                },
                {
                    key: "Cycle starts in:",
                    value: item.days_left != null ? item.days_left + " Days" : "-"
                }
            )

            if (item.census_completed_by == null)
                setCensusCompleted(false);
            else {
                setCensusCompletedDet(item.census_completed_by + " " + moment(item.census_completed_on, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY hh:mm:ss'))
                setCensusCompleted(true);
            }

            deliverydateItems = item.delivery_date_change_options;
            patientlist = item.patients;

            let patientcount = 0;

            for (let patient in patientlist) {
                patientcount = patientcount + 1;
            }

            if (localStorage.getItem("roleId") < 4) {

                cycleSummaryItems.push(
                    {
                        key: "Cycle: ",
                        value: item.cycle_start_date
                    },
                    {
                        key: "Robot: ",
                        value: "Dosis"
                    },
                    {
                        key: "Patients: ",
                        value: patientcount
                    },
                    {
                        key: "Total rxs: ",
                        value: ""
                    },
                    {
                        key: "Filled rxs: ",
                        value: ""
                    },
                    {
                        key: "Fill: ",
                        value: ""
                    },
                    {
                        key: "Check: ",
                        value: ""
                    },
                    {
                        key: "Not Filled Rxs: ",
                        value: ""
                    },
                    {
                        key: "On Hold Rxs: ",
                        value: ""
                    }
                )
            }

            setLocalDeliveryDate(item.delivery_date_change_options);
            setCycleId(item.id);
        });

        setCycleSummaryItems(cycleSummaryItems)
        setCycleInfoItems(cycleInfoItems);
        setCycleDateFlag(true);
        SetPatientDetails(patientlist);
        showCensusGrid(true);
    }

    const handleDelivery = (e) => {

        setDeliveryDate(localdeliverydate);
        if (localStorage.getItem("roleId") < 4) {
            setShowModal(true);
        }
        else {
            if (!censuscompleted)
                toast.error("Please complete census before setting Delivery Date");
            else
                setShowModal(true);
        }

    }

    return (
        <>
            <Row className="g-3">
                <Col xs="9">
                    <Card>
                        <FalconCardHeader
                            title="Cycle Details"
                            titleTag="h6"
                            className="py-2"
                            light
                        />
                        <Card.Body
                            className="h-100"
                            as={Flex}
                            direction="column"
                            alignItems="between"
                        >
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="required">Facility</Form.Label>
                                        <Form.Select
                                            name="facility"
                                            size="sm"
                                            onChange={handleFacilityFieldChange}
                                        >
                                            {options.map((option) => {
                                                let isFacilitySelected = false;
                                                if (option.value == initialFacilityId) {
                                                    isFacilitySelected = "selected";
                                                }
                                                return (
                                                    <option key={option.value} value={option.value} selected={isFacilitySelected}>
                                                        {option.key}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="required">Cycle</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            name="cycle"
                                            onChange={handleCycleDate}
                                        >
                                            {cycledateoptions.map((option) => {
                                                let isCycleSelected = false;
                                                if (option.value == initialCycleDate) {
                                                    isCycleSelected = "selected";
                                                }
                                                return (
                                                    <option key={option.value} value={option.value} selected={isCycleSelected}>
                                                        {option.key}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="m-3">
                                {cycledateflag && (
                                    <Col>
                                        {/* <Card> */}
                                        {/* <Card.Body className="py-5 py-sm-3"> */}
                                        <Row className="g-5 g-sm-0">
                                            {cycleinfoitems.map((item, index) => (
                                                <Col sm={3} key={item.key}>
                                                    <div
                                                        className={classNames({
                                                            'border-sm-end border-500': index !== 3
                                                        })}
                                                    >
                                                        <div className="text-center">
                                                            <h6 className="text-700">{item.key}</h6>
                                                            <h4 className="fw-normal text-700">{item.value}</h4>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                        {/* </Card.Body> */}
                                        {/* </Card> */}
                                    </Col>
                                )}
                            </Row>
                            {cycledateflag && (
                                <Row>
                                    <Col>
                                        <Button variant="falcon-default" size="sm" onClick={handleDelivery}>
                                            Set Delivery
                                        </Button>
                                    </Col>
                                    <Col></Col>
                                    <Col className="mt-2">
                                        {!censuscompleted && (
                                            <h5>Census has not been completed</h5>
                                        )}
                                        {censuscompleted && (
                                            <h5>Census has been completed by {censuscompleteddet} </h5>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3">
                    {(localStorage.getItem("roleId") >= 4) && (
                        <CycleChecklist />
                    )}
                    {(localStorage.getItem("roleId") < 4) && (
                        <>
                            {(cycledateflag) && (
                                <Card className="h-100">

                                    <FalconCardHeader
                                        title="Cycle Summary"
                                        titleTag="h6"
                                        className="py-2"
                                        light
                                    />
                                    <Card.Body
                                        className="h-100"
                                        as={Flex}
                                        direction="column"
                                        alignItems="between"
                                    >
                                        <ul className="fa-ul ms-2 ps-x1 mb-2">
                                            {cyclesummaryitems.map(item => (
                                                <li key={item.key}>
                                                    <h6 className="text-700">
                                                        {item.key} {item.value}
                                                    </h6>
                                                </li>
                                            ))}
                                        </ul>
                                    </Card.Body>

                                </Card>
                            )}
                        </>
                    )}
                </Col>
            </Row>
            <br />
            {censusGrid && (
                <CycleTabDetails patientdetails={patientdetails} />
            )}
            <DeliveryModal show={showModal} setShow={setShowModal} deliverydate={deliverydate} cycleid={cycleId} />
        </>
    );
};

export default CycleDetails;

