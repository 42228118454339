import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = `${process.env.REACT_APP_SERVER_URL}`;
console.log("URL", process.env.REACT_APP_SERVER_URL);

export const socket = io(URL, {
    transports: ['websocket'],
    reconnectionAttempts: 5, // Retry connection 5 times
    reconnectionDelay: 1000, // Wait 1 second before retrying
    timeout: 20000, // Connection timeout of 20 seconds
});