import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as mapYourMedsConstant from '../../common/mapYourMedsConstant';

const columns = [
    // {
    //     accessorKey: 'group',
    //     header: 'Group',
    //     meta: {
    //         headerProps: { className: 'text-900 text-start' },
    //         cellProps: { className: 'text-start' }
    //     }
    // },
    {
        accessorKey: 'facility',
        header: 'Facility',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'cycle',
        header: 'Cycle',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'delivery',
        header: 'Delivery',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'cutoff',
        header: 'Cutoff',
        meta: {
            headerProps: { className: 'text-900 text-start w-10' },
            cellProps: { className: 'text-start' }
        }
    },
    {
        accessorKey: 'censusstatus',
        header: 'Census',
        meta: {
            headerProps: { className: 'text-900 text-center w-10' },
            cellProps: { className: 'text-center' }
        },
        cell: ({ row: { original } }) => {
            return <>
                {original.censusstatus == "Y" && (<FontAwesomeIcon
                    icon="check"
                    className="text-success"
                />)}
                {original.censusstatus == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
            </>
        }
    },
    {
        accessorKey: 'billed',
        header: 'Billed',
        meta: {
            headerProps: { className: 'text-900 text-center w-10' },
            cellProps: { className: 'text-center' }
        },
        cell: ({ row: { original } }) => {
            return <>
                {original.billed == "Y" && (<FontAwesomeIcon
                    icon="check"
                    className="text-success"
                />)}
                {original.billed == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
            </>
        }
    },
    {
        accessorKey: 'robot',
        header: 'Robot',
        meta: {
            headerProps: { className: 'text-900 text-center w-10' },
            cellProps: { className: 'text-center' }
        },
        cell: ({ row: { original } }) => {
            return <>
                {original.robot == "Y" && (<FontAwesomeIcon
                    icon="check"
                    className="text-success"
                />)}
                {original.robot == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
            </>
        }
    },
    {
        accessorKey: 'manual',
        header: 'Manual',
        meta: {
            headerProps: { className: 'text-900 text-center w-10' },
            cellProps: { className: 'text-center' }
        },
        cell: ({ row: { original } }) => {
            return <>
                {original.manual == "Y" && (<FontAwesomeIcon
                    icon="check"
                    className="text-success"
                />)}
                {original.manual == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
            </>
        }
    },
    {
        accessorKey: 'control',
        header: 'Control',
        meta: {
            headerProps: { className: 'text-900 text-center w-10' },
            cellProps: { className: 'text-center' }
        },
        cell: ({ row: { original } }) => {
            return <>
                {original.control == "Y" && (<FontAwesomeIcon
                    icon="check"
                    className="text-success"
                />)}
                {original.control == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
            </>
        }
    },
    {
        accessorKey: 'pv2',
        header: 'PV2',
        meta: {
            headerProps: { className: 'text-900 text-center w-10' },
            cellProps: { className: 'text-center' }
        },
        cell: ({ row: { original } }) => {
            return <>
                {original.pv2 == "Y" && (<FontAwesomeIcon
                    icon="check"
                    className="text-success"
                />)}
                {original.pv2 == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
            </>
        }

    },
    {
        accessorKey: 'manifest',
        header: 'Manifest',
        meta: {
            headerProps: { className: 'text-900 text-center w-10' },
            cellProps: { className: 'text-center' }
        },
        cell: ({ row: { original } }) => {
            return <>
                {original.manifest == "Y" && (<FontAwesomeIcon
                    icon="check"
                    className="text-success"
                />)}
                {original.manifest == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
            </>
        }
    }


];
const CycleStatusGrid = (props) => {

    const [cyclestatus, setCycleStatus] = useState([]);

    useEffect(() => {

        async function fetchCycleGridDetails() {

            let tokenStr = localStorage.getItem("accessToken");

            let cycleStatusIp =
            {
                //"facility_id": props.cyclestatusdata.facility,
                "start_date": props.cyclestatusdata.startdate,
                "end_date": props.cyclestatusdata.enddate
            }

            //console.log("cycleStatusIp " + JSON.stringify(cycleStatusIp));

            const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/cycles/get-cycle-status`, cycleStatusIp, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            const cycleStatusOp = data.cycleData;

            console.log("cycleStatusOp " + JSON.stringify(cycleStatusOp));

            const startdate = new Date(props.cyclestatusdata.startdate);
            const enddate = new Date(props.cyclestatusdata.enddate);

            let loopdate = new Date(startdate);

            let results = [];

            while (loopdate <= enddate) {

                //console.log("looopdate " + cycleStatusOp[moment(loopdate, 'YYYY-MM-DD').format('YYYY-MM-DD')]);

                let dateWiseData = cycleStatusOp[moment(loopdate, 'YYYY-MM-DD').format('YYYY-MM-DD')];

                if (typeof dateWiseData !== "undefined") {

                    dateWiseData.forEach((singleDateData) => {

                        results.push({
                            facilityid: singleDateData.facility_id,
                            facility: get_facility_name(singleDateData.facility_id),
                            cycle: moment(singleDateData.cycle_start_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                            delivery: moment(singleDateData.delivery_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + singleDateData.delivery_time,
                            cutoff: moment(singleDateData.census_before, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                            censusstatus: singleDateData.census_status == 0 ? 'N' : 'Y',
                            billed: singleDateData.billed_status == null ? 'N' : 'Y',
                            robot: singleDateData.tcgrx_fill == null ? 'N' : 'Y',
                            manual: singleDateData.manual_fill == null ? 'N' : 'Y',
                            control: singleDateData.dosis_Fill == null ? 'N' : 'Y',
                            pv2: singleDateData.pv2_Status == null ? 'N' : 'Y',
                            manifest: singleDateData.manifest == 0 ? 'N' : 'Y'
                        });

                    });

                }

                let newDate = loopdate.setDate(loopdate.getDate() + 1);
                loopdate = new Date(newDate);
            }

            let facilityfilteredres = [];

            //console.log("props.cyclestatusdata.facility" + props.cyclestatusdata.facility);
            //console.log("props.cyclestatusdata.censustype" + props.cyclestatusdata.censustype);

            //filter facilityid
            if (props.cyclestatusdata.facility != '') {
                facilityfilteredres = results.filter((singlerecord) => singlerecord.facilityid == props.cyclestatusdata.facility);
            }
            else {
                facilityfilteredres = results;
            }

            let cyclefilteredres = [];
            //filter census type
            if (props.cyclestatusdata.censustype != '') {
                cyclefilteredres = facilityfilteredres.filter((singlerecord) => singlerecord.censusstatus == props.cyclestatusdata.censustype);
            }
            else {
                cyclefilteredres = facilityfilteredres;
            }

            console.log("cyclefilteredres" + JSON.stringify(cyclefilteredres));

            setCycleStatus(cyclefilteredres);
        }

        //fetchCycleGridDetails();

        async function refreshCycleGridDetails() {
            fetchCycleGridDetails();
        }

        //console.log(props.cyclestatusdata.startdate);

        //if (props.cyclestatusdata.startdate > 0) {
        refreshCycleGridDetails();
        //}

    }, [props.cyclestatusdata]);


    //todo make it common
    function get_facility_name(facility_id) {
        let facilityname = "";
        let facilityresults = JSON.parse(localStorage.getItem("facilitiesData"));

        //console.log("facilityresults " + facilityresults + facility_id);

        facilityresults.forEach(function (facilityItem) {
            if (facilityItem.value == facility_id) {
                facilityname = facilityItem.key;
            }
        });

        return facilityname;
    }

    //todo make it common
    function get_group_name(group_id) {
        let grpname = "";
        let grpresults = JSON.parse(localStorage.getItem("groupData"));

        //console.log("facilityresults " + JSON.stringify(facilityresults) + facility_id);

        grpresults.forEach(function (grpItem) {
            if (grpItem.value == group_id) {
                grpname = grpItem.key;
            }
        });

        return grpname;
    }



    // async function fetchCycleStatus() {
    //     let results = [];
    //     results.push({
    //         group: 'Evergreen',
    //         facility: 'Evergreen Mendon 2',
    //         cycle: '10/01/24-11/06/24',
    //         delivery: '10/15/24 10:00-15:00PM',
    //         cutoff: '10/06/24',
    //         censusstatus: 'Y',
    //         billed: 'N',
    //         robot: 'N',
    //         manual: 'Y',
    //         control: 'Y',
    //         pv2: 'Y',
    //         manifest: 'Y'
    //     });
    //     setCycleStatus(results);
    // }

    const table = useAdvanceTable({
        data: cyclestatus,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 20
    });

    return (
        <AdvanceTableProvider {...table}>
            <Row className='mt-3 justify-content-between'>
                <Col xs="4" className="mb-3">
                    <AdvanceTableSearchBox placeholder="Search..." />
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mb-1 mt-1">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle text-center"
                        rowClassName="text-nowrap align-middle text-center"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Col>
            </Row>
            <Card.Footer>
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </Card.Footer>
        </AdvanceTableProvider>
    );
};

export default CycleStatusGrid;