import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

const DrugsAdminModal = ({ show, setShow, patientid, patientname, drugsdata }) => {

    const [patientId, setPatientId] = useState("");
    const [drugs, setDrugsData] = useState([]);
    const [tableOpData, setTableOpData] = useState([]);

    const columns = React.useMemo(() =>
        [
            // {
            //     accessorKey: 'patient',
            //     header: 'Patient',
            //     meta: {
            //         headerProps: { className: 'text-900 text-start' },
            //         cellProps: { className: 'text-start' }
            //     }
            // },
            {
                accessorKey: 'rxnumber',
                header: 'Rxnumber',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'drug',
                header: 'Drug',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'type',
                header: 'Type',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'document',
                header: 'Document',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start w-25' }
                }
            },
            {
                accessorKey: 'details',
                header: 'Details',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'done_in_ips',
                header: 'Done in ips',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25' },
                    cellProps: { className: 'text-start' }
                },
                cell: ({ row: { original } }) => {
                    return <>
                        <Col className="mt-2">
                            <Form.Check
                                type='switch'
                                name={"ips" + original.index}
                                id={"is_yes" + original.index}
                                label='Done in IPS'
                                // checked={
                                //     original.done_in_ips
                                // }
                                onChange={(e) => { handleRowData(e.target.value, original.counter) }}
                            />
                        </Col>
                    </>
                }
            }

        ], []
    );

    useEffect(() => {
        setPatientId(patientid)
        filterDrugsData(drugsdata);

    }, [patientid, patientname, drugsdata]);

    const handleRowData = (gridControlValue, gridControlIndex) => {
        let tempArrData = JSON.parse(localStorage.getItem("drugmodaladminresults"));//tableOpData; 

        //console.log("tableOpData ip" + JSON.stringify(tempArrData));

        tempArrData.forEach(function (arrayItem, index) {
            if (index == gridControlIndex) {
                console.log("index");
                arrayItem["done_in_ips"] = gridControlValue;
            }
        });

        //setTableOpData(tempArrData);
        localStorage.setItem("drugmodaladminresults", JSON.stringify(tempArrData));

        //console.log("tableOpData Op" + localStorage.getItem("drugmodalresults"));

    }

    async function filterDrugsData(drugsdata) {

        let results = [];
        let index = 0;

        console.log("drugsdata" + JSON.stringify(drugsdata));

        for (let singledrugs_index in drugsdata) {

            results.push({
                cycledetailid: drugsdata[singledrugs_index].drugid,
                patient: drugsdata[singledrugs_index].patientname,
                rxnumber: drugsdata[singledrugs_index].rxnumber,
                drug: drugsdata[singledrugs_index].drug,
                type: drugsdata[singledrugs_index].type,
                document: drugsdata[singledrugs_index].document,
                details: drugsdata[singledrugs_index].details,
                done_in_ips: drugsdata[singledrugs_index].done_in_ips,
                counter: index++
            })

        }

        console.log("presresults" + JSON.stringify(results));
        //const tempResults = results;
        //setTableOpData(tempResults);
        localStorage.setItem("drugmodaladminresults", JSON.stringify(results));
        setDrugsData(results);
    }


    const handleSave = async (e) => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let drugsGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-prescriptions`;

        let presOpData = JSON.parse(localStorage.getItem("drugmodaladminresults"));

        console.log("columns" + tableOpData);

        let drugsGridData = []

        presOpData.forEach(function (arrayItem, index) {
            let singlerowitem =
            {
                "cycle_detail_id": arrayItem["cycledetailid"],
                "updated_in_ips": arrayItem["done_in_ips"]
            }
            drugsGridData.push(singlerowitem);
        });

        let finalDrugsData = {
            "cycle_patient_id": patientId,
            "prescriptions": drugsGridData
        }

        console.log("drugsData " + JSON.stringify(finalDrugsData));

        const { data } = await axios.post(drugsGridUrl, finalDrugsData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        console.log("data " + JSON.stringify(data));

        if (Object.keys(data).length > 0) {
            toast.success("Drug Details added successfully");
            setShow(false);
        }
        else {
            toast.error("Error occured while filling Drug Details ");
        }
    }

    const table = useAdvanceTable({
        data: drugs,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10,
        selectionColumnWidth: 52
    });

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Census For  {patientname}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary">
                <Row>
                    <AdvanceTableProvider {...table}>
                        <Row>
                            <Col xs="12">
                                <AdvanceTable
                                    headerClassName="bg-200 text-nowrap align-middle text-center"
                                    rowClassName="text-nowrap align-middle text-center"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col>
                                <AdvanceTableFooter
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </Col>
                        </Row>
                    </AdvanceTableProvider>
                </Row>
                <br />
                <Row>
                    <Col xs="8"></Col>
                    <Col xs="4">
                        <Button color="primary" className="w-100" onClick={handleSave}>Save</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

DrugsAdminModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default DrugsAdminModal;

