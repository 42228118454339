import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const AllergyForm = (props) => {

    const [validated, setValidated] = useState(false);
    const initialFormData = {
        allergy: "",
        isactive: false,
        id: ""
    };

    const [formData, setFormData] = useState(initialFormData);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    const formRef = useRef(null);
    //const navigate = useNavigate();

    useEffect(() => {
        async function setAllergyDetails() {
            //console.log("set edit details " + props.allergyvalue);
            setFormData({
                allergy: props.allergyvalue.split(",")[0],
                isactive: props.allergyvalue.split(",")[1] == 1 ? true : false,
                id: props.allergyvalue.split(",")[2]
            });

            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
        }

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setAllergyDetails();
        }
    }, [props.edittrigger]);

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");

        let pelmedsAllergyUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/patients/add-patient-allergy" :
            `${process.env.REACT_APP_SERVER_URL}` + "/patients/update-patient-allergy";

        let pelmedsAllergyData = buttonStatus == "Add" ? { "allergy": formData.allergy, "is_active": formData.isactive ? 1 : 0 } :
            { "allergy": formData.allergy, "is_active": formData.isactive ? 1 : 0, "id": formData.id }

        console.log("pelmedsAllergyData" + JSON.stringify(pelmedsAllergyData));

        const { data } = await axios.post(pelmedsAllergyUrl, pelmedsAllergyData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("Allergies added/updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Allergies");
        }

        //if (props.status != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        //if (props.status == "Add")
        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Allergy</Form.Label>
                        <Form.Control
                            placeholder='Allergy'
                            value={formData.allergy}
                            name="allergy"
                            onChange={handleFieldChange}
                            type="text"
                            size="sm"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="w-50 mt-4"
                            disabled={!formData.allergy}
                            size="sm"
                        >
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Check
                            type='switch'
                            name="isactive"
                            id='isactive'
                            checked={
                                formData.isactive
                            }
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    isactive: e.target.checked
                                })
                            }
                            label='Active'
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                </Col>
            </Row>
        </Form>
    );
};
export default AllergyForm;
