import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const MedRouteForm = (props) => {

    const initialFormData = {
        route: "",
        isactive: false,
        id: ""
    };

    const [formData, setFormData] = useState(initialFormData);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    const formRef = useRef(null);
    //const navigate = useNavigate();

    useEffect(() => {
        async function setMedRouteDetails() {
            //console.log("set edit details " + props.allergyvalue);
            setFormData({
                route: props.medroutevalue.split(",")[0],
                isactive: props.medroutevalue.split(",")[1] == 1 ? true : false,
                id: props.medroutevalue.split(",")[2]
            });

            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
        }

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setMedRouteDetails();
        }
    }, [props.edittrigger]);

    const handleSubmit = async e => {
        e.preventDefault();

        //console.log("ActiveFormData" + formData.isactive ? 1 : 0)

        let tokenStr = localStorage.getItem("accessToken");
        let pelmedsMedRouteUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/drugs/add-drug-route" : `${process.env.REACT_APP_SERVER_URL}` + "/drugs/update-drug-route";
        let pelmedsMedRouteData = buttonStatus == "Add" ? { "route": formData.route, "is_active": formData.isactive ? 1 : 0 } :
            { "route": formData.route, "is_active": formData.isactive ? 1 : 0, "id": formData.id }

        //console.log("pelmedsMedRouteData " + JSON.stringify(pelmedsMedRouteData));

        const { data } = await axios.post(pelmedsMedRouteUrl, pelmedsMedRouteData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("MedRoute added/updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding MedRoute");
        }

        //if (buttonStatus != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        props.onCallParent();

    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Route Name</Form.Label>
                        <Form.Control
                            placeholder='Route Name'
                            value={formData.route}
                            name="route"
                            onChange={handleFieldChange}
                            type="text"
                            size="sm"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            size="sm"
                            className="mt-4 w-50"
                            disabled={!formData.route}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Check
                            type='switch'
                            id='isactive'
                            name="isactive"
                            checked={
                                formData.isactive
                            }
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    isactive: e.target.checked
                                })
                            }
                            label='Active'
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                </Col>
            </Row>
        </Form>
    );
};
export default MedRouteForm;
