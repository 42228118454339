import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import FacilityTableView from './FacilityTableView';
import EditCurrent from './EditCurrent';

const EditFacilityLookup = (props) => {
  const {id} = props;
  const NavItem = ({ item, classNameCustom }) => {
    return (
      <Nav.Item as="li" className={`${classNameCustom}`}>
        <Nav.Link
          className={`mb-0 py-3 px-1 cursor-pointer`}
          eventKey={item.toLowerCase()}>
          {item}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const [navItems] = useState([ 'Facilitiy-List','Settings']);

  return (
    <>
      <Card className="mt-3">
        <Tab.Container defaultActiveKey="facilitiy-list">
          <Card.Header
            as={Flex}
            justifyContent="between"
            alignItems="center"
            className="p-0 border-bottom patientInfoTabs"
          >
            <Nav
              as="ul"
              className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
            >
              {navItems.map(item => (
                <NavItem classNameCustom="col text-center" item={item} key={item} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            <Row className="g-1">
              <Col xxl={12}>
                <Tab.Content>
                  <Tab.Pane unmountOnExit eventKey="settings">
                    <EditCurrent id = {id}/>
                  </Tab.Pane>
                  <Tab.Pane unmountOnExit eventKey="facilitiy-list">
                    <FacilityTableView id = {id} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  )
}

export default EditFacilityLookup