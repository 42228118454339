import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import DrugsAdminModal from './DrugsAdminModal';
import DrugsNoteModal from './DrugsNoteModal';

const DrugsModal = ({ show, setShow, patientname, patientid, drugsdata }) => {

    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [drugs, setDrugsData] = useState([]);
    const [documentType, setDocumentType] = useState();
    const [cycleDetailId, setCycleDetailId] = useState();
    const [tableOpData, setTableOpData] = useState([]);
    const [showDrugsNoteModal, setShowDrugsNoteModal] = useState(false);


    const columns = React.useMemo(() =>
        [
            {
                accessorKey: 'rxnumber',
                header: 'Rxnumber',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'drug',
                header: 'Drug',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            {
                accessorKey: 'refill',
                header: 'Refill',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start' }
                }
            },
            // {
            //     accessorKey: 'delivered',
            //     header: 'Delivered',
            //     meta: {
            //         headerProps: { className: 'text-900 text-start ' },
            //         cellProps: { className: 'text-start' }
            //     }
            // },
            // {
            //     accessorKey: 'nxtrefill',
            //     header: 'Next Refill',
            //     meta: {
            //         headerProps: { className: 'text-900 text-start ' },
            //         cellProps: { className: 'text-start' }
            //     }
            // },
            {
                accessorKey: 'status',
                header: 'Status',
                meta: {
                    headerProps: { className: 'text-900 text-start' },
                    cellProps: { className: 'text-start w-25' }
                },
                cell: ({ row: { original } }) => {
                    //console.log("status" , original);
                    var dcSeleted = original.change_type == 1 ? 'selected' : false;
                    var labelChangeSelected = original.change_type == 2 ? true : false;
                    var holdSelected = original.change_type == 3 ? true : false;

                    return <>
                        <Form.Select
                            name={"cyclestatus" + original.counter}
                            onChange={(e) => { handleRowData(e.target.value, "status", original.counter) }}
                            size="sm">
                            <option key="Select" value="" >
                                Select
                            </option>
                            <option key="dc" value="1" selected={dcSeleted} >
                                DC
                            </option>
                            <option key="labelchange" value="2">
                                Label Change
                            </option>
                            <option key="hold" value="3">
                                Hold
                            </option>
                        </Form.Select>
                    </>
                }
            },
            {
                accessorKey: 'house',
                header: 'House',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25' },
                    cellProps: { className: 'text-start' }
                },
                cell: ({ row: { original } }) => {
                    // console.log("house" , original);
                    return <>
                        <Form.Control
                            type='number'
                            size='sm'
                            name={"housetxt" + original.counter}
                            value={original.house}
                            onChange={(e) => { handleRowData(e.target.value, "house", original.counter) }}
                        />
                    </>
                }
            },
            {
                accessorKey: 'dayprogram',
                header: 'Program',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25' },
                    cellProps: { className: 'text-start ' }
                },
                cell: ({ row: { original } }) => {
                    return <>
                        <Form.Control
                            type='number'
                            size='sm'
                            name={"daytxt" + original.counter}
                            value={original.dayprogram}
                            onChange={(e) => { handleRowData(e.target.value, "dayprogram", original.counter) }}
                        />
                    </>
                }
            },
            {
                accessorKey: 'loa',
                header: 'LOA',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25' },
                    cellProps: { className: 'text-start ' }
                },
                cell: ({ row: { original } }) => {
                    return <>
                        <Form.Control
                            type='number'
                            size='sm'
                            name={"loatxt" + original.counter}
                            value={original.loa}
                            onChange={(e) => { handleRowData(e.target.value, "loa", original.counter) }}
                        />
                    </>
                }
            }

        ], []
    );

    useEffect(() => {
        setPatientName(patientname);
        setPatientId(patientid)
        filterDrugsData(drugsdata);

        //console.log("patientid" + patientid + patientname);
        // console.log("drugsdata" , drugsdata);

    }, [patientname, patientid, drugsdata]);

    const handleRowData = (gridControlValue, gridControlHeader, gridControlIndex) => {
        let tempArrData = JSON.parse(localStorage.getItem("drugmodalresults"));//tableOpData; 

        //console.log("tableOpData ip" + tempArrData);

        tempArrData.forEach(function (arrayItem, index) {
            if (index == gridControlIndex) {
                console.log("index");
                arrayItem[gridControlHeader] = gridControlValue;
                if (gridControlHeader == "status") {
                    setDocumentType(gridControlValue);
                    setShowDrugsNoteModal(true);
                }
            }
        });

        //setTableOpData(tempArrData);
        localStorage.setItem("drugmodalresults", JSON.stringify(tempArrData));

        //console.log("tableOpData Op" + localStorage.getItem("drugmodalresults"));

    }

    async function filterDrugsData(drugsdata) {

        let results = [];

        // console.log("modaldrugsdata" , (drugsdata));

        let firstrefill = drugsdata[0];
        let index = 0;

        for (let singledrugs in firstrefill) {

            results.push({
                cycledetailid: firstrefill[singledrugs].id,
                rxnumber: firstrefill[singledrugs].pharmacy_order_id,
                drug: firstrefill[singledrugs].drug,
                refill: firstrefill[singledrugs].refill_remaining,
                delivered: '',
                nxtrefill: '',
                status: '',
                house: firstrefill[singledrugs].facility_qty,
                dayprogram: firstrefill[singledrugs].program_qty,
                loa: firstrefill[singledrugs].loa,
                change_type: firstrefill[singledrugs].change_type,
                counter: index++
            })

        }

        //console.log("presresults" + JSON.stringify(results));
        //const tempResults = results;
        //setTableOpData(tempResults);
        localStorage.setItem("drugmodalresults", JSON.stringify(results));
        setDrugsData(results);
    }

    const handleSave = async (e) => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let drugsGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-prescriptions`;

        let presOpData = JSON.parse(localStorage.getItem("drugmodalresults"));

        //console.log("columns" + tableOpData);

        let drugsGridData = []

        presOpData.forEach(function (arrayItem, index) {
            let singlerowitem =
            {
                "cycle_detail_id": arrayItem["cycledetailid"],
                "change_type": arrayItem["status"],
                "facility_qty": arrayItem["house"],
                "program_qty": arrayItem["dayprogram"],
                "loa": arrayItem["loa"],
            }
            drugsGridData.push(singlerowitem);
        });

        let finalDrugsData = {
            "cycle_patient_id": patientId,
            "prescriptions": drugsGridData
        }

        //console.log("drugsData " + JSON.stringify(finalDrugsData));

        const { data } = await axios.post(drugsGridUrl, finalDrugsData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        //console.log("data " + JSON.stringify(data));

        if (Object.keys(data).length > 0) {
            toast.success("Drug Details added successfully");
            setShow(false);
        }
        else {
            toast.error("Error occured while filling Drug Details ");
        }
    }

    const table = useAdvanceTable({
        data: drugs,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        perPage: 10,
        selectionColumnWidth: 52
    });

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Census for {patientName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary">
                <Row>
                    <AdvanceTableProvider {...table}>
                        <Row>
                            <Col xs="12">
                                <AdvanceTable
                                    headerClassName="bg-200 text-nowrap align-middle text-center"
                                    rowClassName="text-nowrap align-middle text-center"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col>
                                <AdvanceTableFooter
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </Col>
                        </Row>
                    </AdvanceTableProvider>
                </Row>
                <br />
                <Row>
                    <Col xs="8"></Col>
                    <Col xs="4">
                        <Button color="primary" className="w-100" onClick={handleSave}>Save</Button>
                    </Col>
                    <DrugsNoteModal show={showDrugsNoteModal} setShow={setShowDrugsNoteModal} docType={documentType} patientId={patientId} cycleDetailId={cycleDetailId} />
                </Row>
            </Modal.Body>
        </Modal>
    );
};

DrugsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default DrugsModal;

