import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ShimmerUI = () => {
  return (
    <div className="container p-3">
      {/* Header Section */}
      <div className="d-flex justify-content-between mb-4">
        {/* Left Section */}
        <div className="d-flex flex-column align-items-start">
          <div className="mb-2">
            <div
              className="bg-secondary shimmer rounded mb-2"
              style={{ height: "10px", width: "150px" }}
            ></div>
            <div
              className="bg-secondary shimmer rounded"
              style={{ height: "10px", width: "200px" }}
            ></div>
          </div>
          <div
            className="rounded-circle shimmer bg-secondary"
            style={{ width: "50px", height: "50px" }}
          ></div>
        </div>
        {/* Right Section */}
        <div className="d-flex gap-3 align-items-start">
          <div
            className="bg-secondary shimmer rounded-pill"
            style={{ height: "30px", width: "120px" }}
          ></div>
          <div
            className="bg-secondary shimmer rounded-pill"
            style={{ height: "30px", width: "150px" }}
          ></div>
        </div>
      </div>

      {/* Tabs */}
      <div className="d-flex gap-2 mb-4">
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className="bg-secondary shimmer rounded"
            style={{ height: "40px", width: "120px" }}
          ></div>
        ))}
      </div>

      {/* Dropdown */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div
          className="bg-secondary shimmer rounded"
          style={{ height: "30px", width: "150px" }}
        ></div>
        <div
          className="bg-secondary shimmer rounded"
          style={{ height: "30px", width: "200px" }}
        ></div>
      </div>

      {/* Table */}
      <div className="mt-4">
        {/* Table Header */}
        <div className="d-flex gap-3 mb-3">
          <div
            className="bg-secondary shimmer rounded"
            style={{ height: "20px", width: "50px" }}
          ></div>
          <div
            className="bg-secondary shimmer rounded"
            style={{ height: "20px", width: "120px" }}
          ></div>
          <div
            className="bg-secondary shimmer rounded"
            style={{ height: "20px", width: "80px" }}
          ></div>
          <div
            className="bg-secondary shimmer rounded"
            style={{ height: "20px", width: "60px" }}
          ></div>
          <div
            className="bg-secondary shimmer rounded"
            style={{ height: "20px", width: "100px" }}
          ></div>
        </div>
        {/* Table Rows */}
        {[...Array(5)].map((_, rowIndex) => (
          <div className="d-flex gap-3 mb-2" key={rowIndex}>
            <div
              className="bg-secondary shimmer rounded"
              style={{ height: "20px", width: "50px" }}
            ></div>
            <div
              className="bg-secondary shimmer rounded"
              style={{ height: "20px", width: "120px" }}
            ></div>
            <div
              className="bg-secondary shimmer rounded"
              style={{ height: "20px", width: "80px" }}
            ></div>
            <div
              className="bg-secondary shimmer rounded"
              style={{ height: "20px", width: "60px" }}
            ></div>
            <div
              className="bg-secondary shimmer rounded"
              style={{ height: "20px", width: "100px" }}
            ></div>
          </div>
        ))}
      </div>

      {/* Keyframes for Shimmer */}
      <style>
        {`
          .shimmer {
            animation: shimmer 1.5s infinite linear;
            background: linear-gradient(
              to right,
              #e0e0e0 0%,
              #f0f0f0 50%,
              #e0e0e0 100%
            );
            background-size: 200% 100%;
          }

          @keyframes shimmer {
            0% {
              background-position: 200% 0;
            }
            100% {
              background-position: -200% 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ShimmerUI;
