import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";

const RefillDiscontinuedModal = ({ show, setShow }) => {

    const [discModalData, setDiscModalData] = useState("");
    const [startMedicineDate, setStartMedicineDate] = useState("");
    const [endMedicineDate, setEndMedicineDate] = useState("");

    let currPresDetails = "";
    let currentModalFormValue = "";

    if (localStorage.getItem("gridFormName") != null) { //Todo Need to find better alternative

        currPresDetails = localStorage.getItem("gridFormName").split(",");

        if (currPresDetails[0] == "lnkrefill")
            currentModalFormValue = "Refill Order"
        else if (currPresDetails[0] == "lnkdisc")
            currentModalFormValue = "Discontinue Reason";

    }


    const handleSubmit = async e => {
        e.preventDefault();

        //if (currPresDetails[0] == "lnkrefill")

        // if (discModalData.trim() != "" || startMedicineDate.trim() != "" || endMedicineDate.trim()) {

        let refillDisPresData = currPresDetails[0] == "lnkrefill" ?
            {
                "id": currPresDetails[1],
                "client_startdate": moment(startMedicineDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                "client_stopdate": moment(endMedicineDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
            } :
            {
                "id": currPresDetails[1],
                "discontinued_note": discModalData
            };

        let refillDisPresUrl = currPresDetails[0] == "lnkrefill" ? `${process.env.REACT_APP_SERVER_URL}/prescriptions/refill-prescription` : `${process.env.REACT_APP_SERVER_URL}/prescriptions/discontinue-prescription`;

        console.log("refillDisPresData " + JSON.stringify(refillDisPresData));

        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.post(refillDisPresUrl, refillDisPresData, { headers: { 'Authorization': `Bearer ${tokenStr}` } })

        if (data.status) {
            setShow(false);
            toast.success("Details updated successfully");
        }
        else {
            toast.error("Error while updating Details");
        }

        setDiscModalData("");
        setStartMedicineDate("");
        setEndMedicineDate("");

        // }
        // else {
        //     toast.error("Empty Spaces Not allowed");
        // }
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    {currPresDetails[0] == "lnkrefill" && (
                        <p>Refill Order</p>
                    )}
                    {currPresDetails[0] == "lnkdisc" && (
                        <p>Discontinue Order</p>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary">
                <Form onSubmit={handleSubmit} name="refilldiscmodalform">
                    <Row>
                        {currPresDetails[0] == "lnkdisc" && (
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label className="required">{currentModalFormValue}</Form.Label>
                                    <Form.Control
                                        value={discModalData}
                                        name="discontinuereason"
                                        onChange={e => setDiscModalData(e.target.value)}
                                        type="text"
                                    />
                                </Form.Group>
                            </Col>
                        )}
                        {currPresDetails[0] == "lnkrefill" && (
                            <Col>
                                <Form.Group>
                                    <Form.Label>Start</Form.Label>
                                    <DatePicker name="startmedicinedate" selected={startMedicineDate} onChange={(startmedicinedate) => setStartMedicineDate(startmedicinedate)}
                                        className='form-control' placeholderText="Select Start Date" minDate={new Date()} />
                                </Form.Group>
                            </Col>
                        )}
                        {currPresDetails[0] == "lnkrefill" && (
                            <Col>
                                <Form.Group>
                                    <Form.Label>Stop</Form.Label>
                                    <DatePicker name="stopmedicinedate" selected={endMedicineDate} onChange={(stopmedicinedate) => setEndMedicineDate(stopmedicinedate)}
                                        className='form-control' placeholderText="Select Stop Date" minDate={new Date()} />
                                </Form.Group>
                            </Col>
                        )}
                        <Col>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    name="btnrefilldiscmodalsubmit"
                                    className="mt-4 w-50"
                                //disabled={!prescriptionModalData}
                                >
                                    Add
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

RefillDiscontinuedModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default RefillDiscontinuedModal;
