import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Col, Row, Button} from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from 'moment';

const AddNotesModal = ({ show, setShow, holdid, called_note }) => {
    
    const [callednotetime, setCalledNoteTime] = useState(null);
    const [callednotedate, setCalledNoteDate] = useState(moment().format('MM/DD/YYYY'));
    const [callednotes, setCalledNotes] = useState();

    const handleUpdate = async (e) => {
        e.preventDefault();
        
        let tokenStr = localStorage.getItem("accessToken");
        let userName = localStorage.getItem("username");
        let calledNotesUrl = `${process.env.REACT_APP_SERVER_URL}/drugs/update-hold-call-notes/`;

        if (callednotedate && callednotetime) {
            let calledNotesData = {
                "hold_id": holdid,
                "is_called" : 1,
                "username": userName,
                "called_note": callednotes,
                "called_date": moment(callednotedate).format("YYYY-MM-DD")+' '+moment(callednotetime).format("HH:mm:ss"),
                
            };

        //    console.log("Called Data " + JSON.stringify(calledNotesData));

            const { data } = await axios.post(calledNotesUrl, calledNotesData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

       //     console.log(data.status);

            if (data.status) {
                toast.success(" Called notes added successfully");
                setShow(false);
            }
            else {
                toast.error("Error occured while adding Called notes ");
            }
            
        }
        else {
             toast.error("All fields are required");
        }

    }

    const handleClose = () => setShow(false);


    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Called Facility
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
            <Form onSubmit={handleUpdate}>
                    <Row>
                        <Col className="col-3">
                            <Form.Group>
                                <Form.Label className="required">Date/Time</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col className="col-4">
                            <Form.Group>
                                <DatePicker 
                                    name="callednotedate" 
                                    selected={callednotedate}
                                    onChange={(callednotedate) => setCalledNoteDate(callednotedate)}
                                    className='form-control form-control-sm' placeholderText="Select Date" />
                            </Form.Group>
                        </Col>
                        <Col className="col-4">
                            <Form.Group>
                                <DatePicker
                                    name="callednotetime" 
                                    popperPlacement='bottom-start'
                                    selected={callednotetime}
                                    onChange={(callednotetime) => setCalledNoteTime(callednotetime)}
                                    className='form-control form-control-sm'
                                    placeholderText="Select Time"
                                    timeIntervals={5}
                                    dateFormat="h:mm aa"
                                    showTimeSelect
                                    showTimeSelectOnly
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-2 col-3">
                            <Form.Group>
                                <Form.Label className="required">Notes</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col className="mt-2 col-8">
                            <>
                                <Form.Group className="mb-3">
                                   <Form.Control textarea
                                   name="callednotes" 
                                   as="textarea"  
                                   placeholder="Enter Note" 
                                   aria-label="With textarea" 
                                   rows="3" 
                                   onChange={(e) => { setCalledNotes(e.target.value) }}
                                   value={callednotes}>{called_note}</Form.Control>                                
                                </Form.Group>
                            </>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 text-center">
                            <Form.Group>
                                <Button 
                                    color="primary"
                                    className="w-20 me-3 text-nowrap align-middle text-center"
                                    onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    name="btncalledmodalsubmit"
                                    className="w-20 text-nowrap align-middle text-center"
                                    >
                                    Update
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

AddNotesModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default AddNotesModal;


