import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const MedAmountForm = (props) => {

  const initialFormData = {
    amount: "",
    //medvalue: " ", //need to change after the API Call
    isactive: false,
    id: ""
  };

  const [formData, setFormData] = useState(initialFormData);
  const formRef = useRef(null);
  const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
  //const navigate = useNavigate();

  useEffect(() => {
    async function setMedAmtDetails() {
      //console.log("set edit details " + props.allergyvalue);
      setFormData({
        amount: props.medamtvalue.split(",")[0],
        isactive: props.medamtvalue.split(",")[1] == 1 ? true : false,
        id: props.medamtvalue.split(",")[2]
      });

      setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
    }

    if (props.edittrigger > 0) {
      //console.log(props.trigger);
      setMedAmtDetails();
    }
  }, [props.edittrigger]);


  const handleSubmit = async e => {
    e.preventDefault();

    //console.log("ActiveFormData" + formData.isactive ? 1 : 0)

    let tokenStr = localStorage.getItem("accessToken");
    let pelmedsMedsAmountUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/drugs/add-drug-amount" : `${process.env.REACT_APP_SERVER_URL}` + "/drugs/update-drug-amount";
    let pelmedsMedsAmountData = buttonStatus == "Add" ? { "amount": formData.amount, "is_active": formData.isactive ? 1 : 0 } :
      { "amount": formData.amount, "is_active": formData.isactive ? 1 : 0, "id": formData.id }

    //console.log("pelmedsMedsAmountData " + JSON.stringify(pelmedsMedsAmountData));

    const { data } = await axios.post(pelmedsMedsAmountUrl, pelmedsMedsAmountData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

    if (data.status) {
      toast.success("Meds Amount added/updated successfully");
      //navigate(0);
    }
    else {
      toast.info("Error while adding Meds Amount");
    }

    //if (props.status != "Edit") {
    setFormData(initialFormData);
    formRef.current.reset();
    setButtonStatus("Add");
    //}

    props.onCallParent();

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3">
            <Form.Label className="required">Amount</Form.Label>
            <Form.Control
              placeholder='Amount'
              value={formData.amount}
              name="amount"
              size="sm"
              onChange={handleFieldChange}
              type="text"
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <Button
              type="submit"
              color="primary"
              className="mt-4 w-50"
              size="sm"
              disabled={!formData.amount}>
              {buttonStatus}
            </Button>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Check
              type='switch'
              name="isactive"
              id='isactive'
              checked={
                formData.isactive
              }
              onChange={e =>
                setFormData({
                  ...formData,
                  isactive: e.target.checked
                })
              }
              label='Active'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
        </Col>
      </Row>
    </Form>
  );
};
export default MedAmountForm;
