import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CycleRefillGrid from './CycleRefillGrid';
import CycleNeedRefillAdminGrid from './CycleNeedRefillAdminGrid';
import CycleWillRefillAdminGrid from './CycleWillRefillAdminGrid';
import CensusAdminGrid from './CensusAdminGrid';
import CensusGrid from './CensusGrid';
import CycleOnHoldGrid from './CycleOnHoldGrid';

const NavItem = ({ item, classNameCustom }) => {
    return (
        <Nav.Item as="li" className={`${classNameCustom}`}>
            <Nav.Link
                className={`mb-0 py-3 px-1 cursor-pointer`}
                eventKey={item.toLowerCase()}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};

const CycleTabDetails = (props) => {
    const [navItems] = localStorage.getItem("roleId") < 4 ? useState(['Census', 'Need-Refill', 'Will-be-Refilled', 'On-Hold']) :
        useState(['Census', 'Need-Refill', 'Will-be-Refilled']);

    return (
        <Card>
            <Tab.Container id="audience-tab" defaultActiveKey="census">
                <Card.Header
                    as={Flex}
                    justifyContent="between"
                    alignItems="center"
                    className="p-0 border-bottom patientInfoTabs"
                >
                    <Nav
                        as="ul"
                        className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
                    >
                        {navItems.map(item => (
                            <NavItem classNameCustom="col text-center" item={item} key={item} />
                        ))}
                    </Nav>
                </Card.Header>

                <Card.Body>
                    <Row className="g-1">
                        <Col xxl={12}>
                            <Tab.Content>
                                <Tab.Pane unmountOnExit eventKey="census">
                                    {localStorage.getItem("roleId") < 4 && (
                                        <CensusAdminGrid patientdetails_obj={props.patientdetails} />
                                    )}
                                    {localStorage.getItem("roleId") >= 4 && (
                                        <CensusGrid patientdetails_obj={props.patientdetails} />
                                    )}
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="need-refill">
                                    {localStorage.getItem("roleId") < 4 && (
                                        <CycleNeedRefillAdminGrid patientdetails_arr={props.patientdetails} status="needrefill" />
                                    )}
                                    {localStorage.getItem("roleId") >= 4 && (
                                        <CycleRefillGrid patientdetails_arr={props.patientdetails} status="needrefill" />
                                    )}
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="will-be-refilled">
                                    {localStorage.getItem("roleId") < 4 && (
                                        <CycleWillRefillAdminGrid patientdetails_arr={props.patientdetails} status="willrefill" />
                                    )}
                                    {localStorage.getItem("roleId") >= 4 && (
                                        <CycleRefillGrid patientdetails_arr={props.patientdetails} status="willrefill" />
                                    )}
                                </Tab.Pane>
                                <Tab.Pane unmountOnExit eventKey="on-hold">
                                    {/* <CycleOnHoldGrid/> */}
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Card.Body>
            </Tab.Container>
        </Card>
    );
};

NavItem.propTypes = {
    item: PropTypes.string.isRequired
};

export default CycleTabDetails;