import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const InsurancePriorityForm = (props) => {

    const initialFormData = {
        insurancepriority: "",
        isactive: false,
        id: ""
    };

    const [formData, setFormData] = useState(initialFormData);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    const formRef = useRef(null);
    //const navigate = useNavigate();

    useEffect(() => {
        async function setInsPrDetails() {
            //console.log("set edit details " + props.allergyvalue);
            setFormData({
                insurancepriority: props.insprvalue.split(",")[0],
                isactive: props.insprvalue.split(",")[1] == 1 ? true : false,
                id: props.insprvalue.split(",")[2]
            });

            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
        }

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setInsPrDetails();
        }
    }, [props.edittrigger]);

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let pelmedsInsPrUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/patients/add-patient-insurance-priority" :
            `${process.env.REACT_APP_SERVER_URL}` + "/patients/update-patient-insurance-priority";

        let pelmedsInsPrData = buttonStatus == "Add" ? { "priority": formData.insurancepriority, "is_active": formData.isactive ? 1 : 0 } :
            { "priority": formData.insurancepriority, "is_active": formData.isactive ? 1 : 0, "id": formData.id }

        //console.log("pelmedsInsPrData" + JSON.stringify(pelmedsInsPrData));

        const { data } = await axios.post(pelmedsInsPrUrl, pelmedsInsPrData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("Insurance Priority added/updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Insurance Priority");
        }

        //if (props.status != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Insurance Priority</Form.Label>
                        <Form.Control
                            placeholder='Insurance Priority'
                            value={formData.insurancepriority}
                            name="insurancepriority"
                            size="sm"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            size="sm"
                            className="mt-4 w-50"
                            disabled={!formData.insurancepriority}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Check
                            type='switch'
                            name="isactive"
                            id='isactive'
                            checked={
                                formData.isactive
                            }
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    isactive: e.target.checked
                                })
                            }
                            label='Active'
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                </Col>
            </Row>
        </Form>
    );
};
export default InsurancePriorityForm;
