import React, { useState } from 'react'
import { Col, Dropdown, Form, InputGroup, Modal, Row, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';


const AddOrderModal = ({ show, setShow }) => {

  const [drugOptions, setDrugOptions] = useState([
    { name: "ACETAMINOPHEN 325 MG (refill -10)", qty: "120", rxNumber: "7228283" },
    { name: "METOPROLOL SUCCINATE 25 MG (refill -11)", qty: "90", rxNumber: "9238238" },
    { name: "OLANZAPINE 7.5 MG (refill -11)", qty: "45", rxNumber: "3459298" },
    { name: "SPIRIVA RESPIMAT 2.5 mcg/actuation (refill -11)", qty: "60", rxNumber: "8765892" },
    { name: "TRADJENTA 5 MG (refill -11)", qty: "30", rxNumber: "5648234" },
  ]);
  const [selectedDrugs, setSelectedDrugs] = useState([]);
  const [newDrug, setNewDrug] = useState("");
  const [note, setNote] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);

  const location = useLocation();
  const state = location?.state;

  const handleDrugSelection = (drug) => {
    if (!selectedDrugs.some((selected) => selected.name === drug.name)) {
      setSelectedDrugs([...selectedDrugs, drug]);
      setDrugOptions(drugOptions.filter((value) => value.rxNumber != drug.rxNumber))
    }
  };

  const handleRemoveDrug = (drug) => {
    setSelectedDrugs(selectedDrugs.filter((selected) => selected.name !== drug.name));
    drugOptions.push(drug)
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  async function handleSubmit(e) {
    e.preventDefault();
  }
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5" id="copyLinkModalLabel">
          ADD Order for {state?.individual}/{state?.facilityname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-body-tertiary p-4">
        <Form onSubmit={handleSubmit} name="addorderform">
          <Row>
            <Col xs={6}>
              <Form.Group className="mb-2">
                <Form.Label className="required">Delivery On</Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Label className="required">Order Type</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Group className="mb-3">
                <Form.Select
                  name="selectdeliverydate"
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Select
                  name="selectordertype"
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center my-4">
              <div className="flex-grow-1 border-top"></div>
              <Form.Label className="mx-3 text-muted fw-bold">Existing Drug</Form.Label>
              <div className="flex-grow-1 border-top"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group >
                <InputGroup className="d-flex justify-content-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" className='w-50'>
                      select drug to order
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='w-50'>
                      {drugOptions.map((drug, index) => (
                        <Dropdown.Item key={index} onClick={() => handleDrugSelection(drug)}>
                          {drug.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          {selectedDrugs?.length ? <Row className="mt-4">
            <Col xs={5} sm={6} md={6}>
              <Form.Label>Drug Name</Form.Label>
            </Col>
            <Col>
              <Form.Label>Qty</Form.Label>
            </Col>
            <Col xs={3} sm={2} md={2} >
              <Form.Label>Rx Number</Form.Label>
            </Col>
            <Col>
              <Form.Label></Form.Label>
            </Col>
          </Row> : []}
          {selectedDrugs.map((drug, index) => (
            <Row key={index} className="mb-3">
              <Col xs={5} sm={6} md={6}>
                <Form.Control type="text" value={drug.name} readOnly />
              </Col>
              <Col xs={3} sm={2} md={2} >
                <Form.Control type="text" value={drug.qty} readOnly />
              </Col>
              <Col xs={3} sm={3} md={3}>
                <Form.Control type="text" value={drug.rxNumber} readOnly />
              </Col>
              <Col className='mt-1'>
                <Col
                  className="badge bg-primary text-white cursor-pointer"
                  onClick={() => handleRemoveDrug(drug)}
                >
                  ×
                </Col>
              </Col>
            </Row>
          ))}
          <Row>
            <Col className="d-flex align-items-center my-4">
              <div className="flex-grow-1 border-top"></div>
              <Form.Label className="mx-3 text-muted fw-bold">OR</Form.Label>
              <div className="flex-grow-1 border-top"></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>New Drug</Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter new drug name..."
                  value={newDrug}
                  onChange={(e) => setNewDrug(e.target.value)}
                />
              </Form.Group>

            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Note</Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder="Add any notes here..."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Document Upload</Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Control size="sm" type="file" onChange={handleFileUpload} />
              {uploadedFile && <div className="mt-2">Uploaded File: {uploadedFile.name}</div>}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Label className="text-danger text-center fw-bold">
                *Please call pharmacy for urgent or after hours deliveries.
              </Form.Label>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Button type="submit"
              color="primary"
              name="addordermodalsubmit"
              className='w-25'>
              Submit
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default AddOrderModal